import React, { Component } from 'react';
import toastr, { success } from 'toastr';
import objectToFormdata from 'object-to-formdata';
import Loadable from 'react-loading-overlay';
import urlParse from 'url-parse';
import reactWindowSize from 'react-window-size';
import 'video-react/dist/video-react.css';
import apiClient from '../util/api-client';
import ListFileErrors from './ListFileError';
import { reload as reloadActivities } from './ActivitiesButton';
import { Progress } from 'reactstrap';
import axios from 'axios';
import UploadSection from './UploadSection';
import FileTable from './FileTable';


const url = new URL(location.href);

const ONE_GB_TO_BYTES = Math.pow(2,30);
const PLANS = ['free', 'minimum', 'basic', 'master']
const PLANS_TEXT = ['無料','ミニマム','ベーシック','マスター']
const REGEXP_URL = /projects\/(\d+)\/(\d+)\/akaire\/(\S)/
const TICKET_REGEXP_URL = /projects\/(\d+)\/(\d+)\/(\d+)\/akaire\/(\S)/
const MAX_FILES = 20;
const UNIT_CAPACITY = 1024;
const _3_GB = 3 * 1024 * 1024 * 1024;
const csrfTokenTag = document.querySelector('meta[name="csrf-token"]');

class NewMultipleAkaireFiles extends Component {
  constructor(props) {
    super(props);
    const { id, baseURL, last_page_id, is_create_video_embed, is_create_pass_video, current_total_size } = props;
    this.props = { ...this.props, ...props };
    this.state = {
      files: [],
      numberShowFile: 10,
      fileErrors: [],
      fileUploadErrors: [],
      fileUploadSuccess: [],
      user: {},
      project: {},
      loadingText: null,
      percentComplete: 0,
      animations: [],
      markers: [],
      pages: [],
      currentAnimationId: null,
      currentPageId: last_page_id,
      assignments: [],
      shouldShowGetUrlModal: false,
      shouldShowProjectShareModal: false,
      shouldShowProjectFormModal: false,
      shouldShowAnimationUploaderModal: false,
      shouldShowAnimationUploaderImageModal: false,
      shouldShowCompletionModal: false,
      shouldShowPublicUrlModal: false,
      shouldShowGetUrlYoutube: false,
      isLoading: false,
      activeTab: 'markers',
      timerCheckAnimation: null,
      responseConvertVideo: null,
      timerDownloadExtensionFile: null,
      downloadExtensionUrl: null,
      convertFile: false,
      completed: 0,
      finishConvert: false,
      changeCss: false,
      uploadedDataInfo: {},
      isNewAnimationCreated: false,
      membersInComment: [],
      animationSelectCurrent: null,
      is_create_video_embed: is_create_video_embed,
      is_capture_video_youtube: false,
      is_cancel_capture_video_youtube: false,
      isShowFullscreenAnimation: false,
      is_create_pass_video: is_create_pass_video,
      isShowInputPassVideo: false,
      passwordShareVideo: '',
      isDisabledButtonSavePass: true,
      isShowInputDemo: false,
      currentPasswordShareVideo: '',
      currentTotalSize: current_total_size,
      showProgressBar: false,
      convert_file: false,
      nameLastFile: '',
    };
    this.currentURL = baseURL;
    this.baseURL = baseURL
    this.apiClient = apiClient({ baseURL: this.baseURL });
    this.apiClientGetLink = axios.create({
      headers: {
        'X-CSRF-TOKEN': csrfTokenTag && csrfTokenTag.getAttribute('content') || '',
        'Accept': 'application/json'
      },
    });
    this.percentConvert = null;
  }

  componentDidUpdate(_, prev) {
    const { is_cancel_capture_video_youtube, is_capture_video_youtube } = this.state;
    if (is_cancel_capture_video_youtube && is_capture_video_youtube !== prev.is_capture_video_youtube &&
      is_capture_video_youtube === false && prev.is_capture_video_youtube === true) {
      this.setState({is_cancel_capture_video_youtube: false})
    }
  }

  toggleElementList(className, show = true) {
    const elementList = document.getElementsByClassName(className);
    for (let i = 0; i < elementList.length; i ++) {
      elementList[i].style.visibility = show ? "visible" : "hidden";
    }
  }

  currentAnimation = () => this.state.animations.find(_ => _.id === this.state.currentAnimationId)
  fromProjectId = () => {
    return url.searchParams.get('from_project_id');
  }
  fromTaskId = () => {
    return url.searchParams.get('from_task_id');
  }
  fromWorkId = () => {
    return url.searchParams.get('from_work_id');
  }
  fromPageId = () => {
    return url.searchParams.get('from_page_id');
  }
  textFor = (plan) => {
    return PLANS.indexOf(plan) >= 0 && PLANS_TEXT[PLANS.indexOf(plan)]
  }
  showAlertLimitVideo = () => {
    const {current_limit_video: currentLimitVideo, current_subscription_type: currentSubscriptionType} = this.props;
    const nextLevelPlan = this.textFor(PLANS[PLANS.indexOf(currentSubscriptionType)+1])
    const text = nextLevelPlan ? `${nextLevelPlan}プラン以上にアップグレードするか、データの圧縮をしてからアップロードをお願い致します。` : ''
    toastr.error(`${this.textFor(currentSubscriptionType)}プランの場合、1回でのアップロード制限は${currentLimitVideo}GB以内です。${text}`);
  }

  convertVideo = async (signedUrl, filename) => {
    const checkUploadNewPage = $('#uploadNewPagePopup').attr('data-upload-new') == 'true'
    const pageId = checkUploadNewPage ? 'new' : $('#uploadVersionPopup').attr('data-page-id')
    const convertPromise = new Promise(async (resolve, reject) => {
      while (true) {
        try {
          const res = await this.apiClient.put(
            `${pageId ? 'animations/convert_mp4' : 'convert_mp4'}`,
            {
              pathname: decodeURI(urlParse(signedUrl).pathname),
              page_id: pageId,
              filename: filename,
              from_project_id: this.fromProjectId() ,
              from_task_id: this.fromTaskId(),
              from_work_id: this.fromWorkId(),
              from_page_id: this.fromPageId(),
            }) // decode the file name to the correct original name
            if (res.data.type === 'success') {
              setTimeout(() => {
                reloadActivities();
                this.setState({ convertFile: false });
                this.finishConvert(true);
              }, 1000);
              setTimeout(() => {
                this.endLoading();
              }, 2000);
              resolve(true); // Resolve promise khi convert hoàn thành
              break; // Thoát khỏi vòng lặp
            }
            await new Promise((resolve) => setTimeout(resolve, 2000));
        } catch (error) {
          this.endLoading();
          // this.close('AnimationUploaderModal');
          reject(error);
          break;
        }
      }
    });
    const isDoneConvert = await convertPromise;
    return isDoneConvert;
  }
  file_extension = (filename) => {
    const extensions = filename.split('.').slice(-1)
    if (extensions.length) { return extensions[0].toUpperCase() }
  }

  finalizeAnimation = () => {
    const { currentAnimationId } = this.state;
    this.startLoading('Now processing...');
    return this.apiClient.post(`animations/${currentAnimationId}/finalization`)
      .then(() => {
        toastr.success('完成しました');
        // reloadActivities();
      })
      .catch(() => {
        toastr.error('完成できませんでした');
      })
      .finally(this.endLoading);
  }

  toggle = bool => name => {
    this.setState({ [`shouldShow${name}`]: bool });
    if(!bool) {
      if($('#uploadNewPagePopup').attr('data-upload-new') == 'true') {
        $('#uploadNewPagePopup').modal('show')
      } else {
        $('#uploadVersionPopup').modal('show')
      }
    }
  }
  open = this.toggle(true)
  close = this.toggle(false)

  changeURL = (item, newPage = false, idAkaire = null) => {
    let baseURL = this.props.baseURL;
    if(baseURL && baseURL.match(REGEXP_URL)) {
      let pageId = 'new';
      let versionId = 'new';
      let akareId = 'new';
      this.state.pages.map((e,i) => {
        if (item == e.id) {
          pageId = e.id;

          if (this.state.animations.length > 0 && !newPage) {
            this.state.animations.map((item, index) => {
              if (item.id === this.state.currentAnimationId) {
                versionId = item.id;
              }
            });
          }
        }
      });

      if (idAkaire) {
        akareId = idAkaire;
      }
      if (akareId == 'new' && pageId == 'new' && versionId == 'new') {
        let url = '/projects/' + baseURL.match(REGEXP_URL)[1] + '/' + baseURL.match(REGEXP_URL)[2] + '/akaire/new';
      }
      else {
        let url = '/projects/' + baseURL.match(REGEXP_URL)[1] + '/' + baseURL.match(REGEXP_URL)[2] + '/akaire/'+ akareId + '/' + pageId + '/' + versionId;
      }
      window.history.pushState( "", "", url );
    }
  }

  startLoading = loadingText => {
    this.fadeInLoader();
    this.setState({ loadingText, isLoading: true });
  }
  endLoading = () => {
    this.setState({ loadingText: null, isLoading: false });
    $('.preloader').fadeOut();
    clearInterval(this.percentConvert);
    this.percentConvert = null;
  }
  convertFile = (file) => this.setState({ convertFile: file })
  finishConvert = (type) => {
    this.setState({ finishConvert: type })
  }
  onSubmitCompletionModal = () => {
    this.finalizeAnimation().then(this.close.bind(this, 'CompletionModal'));
  }

  reloadAfterUpload = () => {
    const countAllFiles = this.state.files.length
    window.location.href =
      Routes.list_temp_file_akaire_feature_akaires_path(
        { upload_all: true, allFiles: countAllFiles }
      )
  }

  fadeInLoader = () => {
    const pageId = $('#uploadVersionPopup').attr('data-page-id')
    if(pageId) {
      $('.preloader').fadeIn();
      setInterval(() => {
        $('.preloader').css('opacity', 0.2)
      }, 200);
    }
  }

  changeFocus = () => {
    const {isShowInputDemo} = this.state
    this.setState({isShowInputDemo: !isShowInputDemo});
  }

  changeMultipleFiles = (e) => {
    const listFiles = Array.from(e.target.files)
    this.addFiles(listFiles)
  }

  handelOnDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.addFiles(Array.from(e.dataTransfer.files))
      e.dataTransfer.clearData();
    }
  }

  addFiles = (files) => {
    const listFileErrors = Array.from(this.state.fileErrors)
    const validFiles = files.filter((file) => {
      if (file.size > 3 * UNIT_CAPACITY * UNIT_CAPACITY * UNIT_CAPACITY) { // 3GB in bytes
        const isPresent = listFileErrors.find((f) => file.name === f.name && f.size === file.size)
        if (!isPresent) listFileErrors.push(file);
        return false;
      }
      return true;
    });
    this.setState({ fileErrors: listFileErrors })
    

    if (this.state.files.length + validFiles.length > MAX_FILES) {
      Popup.alert(`選択できるファイルは最大 ${MAX_FILES} 個までです`);
      return; // Stop adding files if the limit is reached
    }

    const uniqueFiles = validFiles.filter((newFile) => {
      return !this.state.files.some((existingFile) => existingFile.name === newFile.name && existingFile.size === newFile.size);
    });

    this.setState({files: [...this.state.files, ...uniqueFiles]});
  };

  handleDeleteFile = (file, index) => {
    const listFiles = Array.from(this.state.files)
    const newListFiles = listFiles.filter((f) => f.name !== file.name && f.size !== file.size)
    this.setState({files: newListFiles});
  };

  handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleUploadFiles = async (e) => {
    this.startLoading(`files is uploading...`)
    const filesUploadErrors = []
    const fileUploadSuccess = []
    for (const file of this.state.files) {
      try {
        await this.handleUploadFileToS3(file)
        fileUploadSuccess.push(file)
      } catch (e) {
        filesUploadErrors.push(file)
      }
    };
    this.setState({ fileUploadErrors: filesUploadErrors })
    this.setState({ fileUploadSuccess: fileUploadSuccess })
    try {
      await this.sendMailWhenUploadMultipleFiles(filesUploadErrors)
      Popup.alert('動画をアップロードしました。');
    } catch (e) {
      Popup.alert('アップロードプロセスは成功しましたが、メール送信プロセスでエラーが発生しました');
    }
    this.endLoading();
    this.changeURL(this.state.currentPageId);
    this.reloadAfterUpload()
  }

  sendMailWhenUploadMultipleFiles = async (filesUploadErrors) => {
    const listErrorNames = filesUploadErrors.map((file) => file.name)
    const listFileUploads = this.state.files.map((file) => file.name)
    const data = {
      list_errors: listErrorNames,
      list_uploads: listFileUploads
    }
    await this.apiClientGetLink.post(`send_upload_report_email`, data)
      .then((response) => {
      })
      .catch((error) => {
        throw new Error(error)
      })
  }

  handleUploadFileToS3 = async (file) => {
    const url = await this.handleGetLinkUpload(file)
    const fileName = file.name.replace(/[^\w\d_\-.]+/ig, '');
    const type = file.type
    const uploadResponse = await axios.put(url, file, {
      headers: {
        'Content-Type': type,
        'x-amz-acl': 'private'
      }
    });
    const checkUploadNewPage = $('#uploadNewPagePopup').attr('data-upload-new') == 'true'
    const pageId = checkUploadNewPage ? 'new' : $('#uploadVersionPopup').attr('data-page-id')
    const isConvertFile = type == 'video/x-msvideo' || type == 'video/avi' || type.includes('wmv') || type === 'video/quicktime'
    this.setState({ convert_file: isConvertFile })
    await this.apiClient.post(
      `${pageId ? 'animations' : ''}`,
      {
        pathname: decodeURI(urlParse(url).pathname),
        page_id: pageId,
        filename: fileName,
        from_project_id: this.fromProjectId(),
        from_task_id: this.fromTaskId(),
        from_work_id: this.fromWorkId(),
        from_page_id: this.fromPageId(),
      })
      .then(async(response) => {
        if (isConvertFile) {
          window.time = (((file.size / 1024) * 60000) / (40 * 1024)) + 10000
          const isDoneConvert = await this.convertVideo(url, fileName)
        }
      })
  }

  handleGetLinkUpload = async (file) => {
    const name = file.name.replace(/[^\w\d_\-.]+/ig, '')
    return await this.apiClientGetLink.get(
      `animations/new?objectName=${name}&contentType=${file.type}`)
      .then(async(response) => {
        return response.data.signedUrl
      })
      .catch((error) => {
        throw new Error(error)
      })
  }

  onUploadProgress = (percent) => {
    this.setState({ showProgressBar: true })
    if(!this.state.convert_file){
      this.setState({ completed: percent })
    }
  }

  renderNormal() {
    const { current_user_id: currentUserId, role, id, root_url: rootUrl, statuses,
      is_free_plan: isFreePlan, current_limit_video: currentLimitVideo, token_public_url: token_public_url,
      category, is_akapon_member: isAkaponMember, is_project_owner_member, isAkaponProject, baseURL
    } = this.props;
    const {
      files, numberShowFile,
      loadingText, isLoading, shouldShowGetUrlModal, completed, showProgressBar, fileErrors
    } = this.state;
    const currentAnimation = this.currentAnimation();
    const token = token_public_url;
    const public_video_token = currentAnimation && currentAnimation.url_token;
    var darkGray = 'l-container u-bg--darkGray py-3 mt-2 mt-sm-0';
    var progressBar = '';
    if (!currentAnimation) {
      darkGray = darkGray.concat(" darkGray-animation")
    }
    if (showProgressBar) {
      progressBar = (
        <Progress value={completed}>{completed}%</Progress>
      )
    }
    const filledData = files.length < numberShowFile ? Array.from({ length: 10 }, (_, index) => files[index]) : files;
    return (
      <div className="animation-inspector">
        <Loadable
          className="loading-overlay"
          active={isLoading}
          text={loadingText || 'Now loading...'}
          zIndex={10000}
        >
          <div className={darkGray}>
            <div className="l-main">
              <div className="pane-wrapper">
                {/* アニメーション */}
                <div className='create-akaire-box-multiples text-center'>
                  <UploadSection
                    handleOnDrop={this.handelOnDrop}
                    handleDragLeave={this.handleDragLeave}
                    handleDragOver={this.handleDragOver}
                    changeMultipleFiles={this.changeMultipleFiles}
                    openGetUrlModal={this.open.bind(this, 'GetUrlModal')}
                  />
                  {progressBar}
                  <hr className='text-black' width='100%'size='1px' ></hr>
                  <FileTable
                    files={files}
                    filledData={filledData}
                    handleDeleteFile={this.handleDeleteFile}
                    handleUploadFiles={this.handleUploadFiles} />
                </div>
              </div>
              {/* / pane-wrapper */}
            </div>
            {/* / l-main */}
          </div>
          {/* / l-container */}


          <ListFileErrors isOpen={shouldShowGetUrlModal} onClickClose={this.close.bind(this, 'GetUrlModal')} token={token}
            rootUrl={rootUrl} files={fileErrors}
            baseURL={this.baseURL} isAkaponProject={isAkaponProject} isCreateOfTemp={true}/>
        </Loadable>
      </div>
    )
  }

  render() {
    return this.renderNormal();
  }
}

export default reactWindowSize(NewMultipleAkaireFiles);

export { NewMultipleAkaireFiles };
