import React from 'react';

const ToggleSectionOfCaptureUrls = ({ title, toggleState, onToggle, onHelpClick }) => (
  <div className="row">
    <div
      className="font-weight-bold cursor-pointer fs-title-popup-url text-left text-black text-underline"
      onClick={onToggle}
    >
      <i
        className={`pr-2 text-muted fa fa-chevron-${
          toggleState ? "up" : "down"
        }`}
      ></i>
      {title}
    </div>
    <i
      className="fas fa-question-circle ml-1 scale-1_3 mt-1 cursor-pointer text-black"
      onClick={onHelpClick}
    ></i>
  </div>
);

export default ToggleSectionOfCaptureUrls;
