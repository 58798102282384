import React from 'react';
import { Input } from 'reactstrap';

const AdvancedSettings = ({
  supportSpecialPage,
  supportLazyLoad,
  checkboxChange,
  showPopupCaptureUrl
}) => (
  <div className="my-2 row w-100">
    <div className="vl w-100">
      <div className="">
        <div className="bg-soft-peach p-3 text-left text-black">
          <span className="font-weight-bold">追加オプション</span>
          <div className="ml-4 mt-2 option-screen-capture">
            <Input
              type="checkbox"
              name="supportSpecialPage"
              onChange={(event) => checkboxChange(event)}
              className="scale-1_5"
              id="supportSpecialPage"
              checked={supportSpecialPage}
            />
            <label
              htmlFor="supportSpecialPage"
              className="font-weight-bold"
            >
              {" "}
              特殊なページに対応
            </label>
            <i
              className="fas fa-question-circle ml-3 scale-1_3 cursor-pointer"
              onClick={() => {showPopupCaptureUrl('support-special-page')}}
            ></i>
          </div>
          <div className="ml-4 option-screen-capture">
            <Input
              type="checkbox"
              name="supportLazyLoad"
              onChange={(event) => checkboxChange(event)}
              className="scale-1_5"
              id="supportLazyLoad"
              checked={supportLazyLoad}
            />
            <label htmlFor="supportLazyLoad" className="font-weight-bold">
              {" "}
              遅延読み込みに対応
            </label>
            <i
              className="fas fa-question-circle ml-3 scale-1_3 cursor-pointer"
              onClick={() => {showPopupCaptureUrl('support-lazy-load')}}
            ></i>
          </div>
          <div className="">
            <p className="small">
              ※CSSやJavaScriptの影響でうまく表示されないときにお試しください。 
              <br />
              <span className='ml-3'>追加オプションを利用すると、アップロードに時間が掛かる場合があります。</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AdvancedSettings;
