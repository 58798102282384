export default function popupWhenCaptureUrls(text) {
  let messeger = '';
    var linkIdPass = `${gon.is_plan_akapon ? 'https://kanritools.com/support/#2-4-3-3' : 'https://createcloud.jp/support/#6-1-2-3'}`
    switch(text){
      case 'support-special-page-all':
        messeger = `<div class="align-center-div text-left max-width-750px mt-2">
                    <div class="text-center fs-20 mb-3 font-weight-bold">高度なキャプチャ設定とは</div>
                    <span class='font-weight-bold'>●特殊なページに対応</span><br>
                    <span class='ml-3'> スクロールに合わせて画像や文字が動くページ（視差効果・パララックス）や、<br class='d-none d-sm-block'>
                    ブラウザの幅に合わせてメイン画像がリサイズされるページフレックスサイズヘッダー）<br class='d-none d-sm-block'>
                    の時にお試しください。</span><br/>
                    <br/>
                    <span class='font-weight-bold'>●遅延読み込みに対応 </span><br>
                    <span class='ml-3'>画像を一度に読み込まず、スクロ一ルごとに必要な分だけ読み込むペ一ジの時にお試しください。</span>
                    </div>`;
        break;
      case 'support-lazy-load-all':
        messeger = `<div class="align-center-div text-left max-width-650px mt-2">
                    <div class="text-center fs-20 mb-3 font-weight-bold">ID・PASSが必要なサイトとは</div>
                    <span class='font-weight-bold'>●ログイン情報</span><br>
                    <span class='ml-3'>会員サイトなど、ID・PASSのログイン情報が必要なページの場合、<br/>
                    ID｜PASS情報を入力すると、ログイン後のページ赤入れができます。<br/>
                    詳しくは<a href=${linkIdPass} target="_blank" class="text-underline text-white">こちら</a>をご覧ください。
                    </span></br>
                    <br/>
                    <span class='font-weight-bold'>●ベーシック認証（Basic認証）とは </span><br>
                    <span class='ml-3'>HTTPで定義されてる認証方式の一つで、基本認証とも呼ばれています。<br>
                    主に、テストサイトなどで使用される『ユーザーID・パスワード』です。
                    </span>
                    </div>`;
        break;
      case 'login-info':
        messeger = `<div class="align-center-div text-left max-width-650px mt-2">
                    <span class='font-weight-bold'>●ログイン情報</span><br>
                    会員サイトなど、ID・PASSのログイン情報が必要なページの場合、<br/>
                    ID｜PASS情報を入力すると、ログイン後のページ赤入れができます。<br/>
                    詳しくは<a href=${linkIdPass} target="_blank" class="text-underline text-white">こちら</a>をご覧ください。
                    </div>`;
        break;
      case 'basic-auth':
        messeger = `<div class="align-center-div text-left max-width-650px mt-2">
                    <span class="font-weight-bold">●ベーシック認証（Basic認証）とは</span><br>
                    HTTPで定義されてる認証方式の一つで、基本認証とも呼ばれています。<br/>
                    主に、テストサイトなどで使用される『ユーザーID・パスワード』です。
                    </div>`;
        break;
      case 'option-save-account':
        messeger = `<div class="align-center-div text-left max-width-1000px mt-2">
                    <div class="text-center fs-20 mb-3 font-weight-bold">ID・パスワードの保存とは</div>
                    <span class='font-weight-bold'>【保存する】</span><br/>
                    次回以降、同じID｜PASSの「違う赤入れページ（新規）」を作成する時、「ID・PASS」が保存された状態になります。<br/>
                    ※他のメンバーには保存されません。<br/>
                    ※アカウント単位で異なるID・PASSを保存できます。<br/>
                    <br>
                    <span class='font-weight-bold'>【保存しない】</span><br/>
                    次回以降、同じID｜PASSの「違う赤入れページ（新規）」を作成する時、都度「ID・PASS」の入力が必要になります。
                    <br/>
                    ※ID・PASSにつきましては、会員様の責任のもと、管理をお願い致します。
                    </div>`;
        break;
      case 'support-special-page':
        messeger = `<div class="align-center-div text-left max-width-750px mt-2">
                    <span class='font-weight-bold'>●特殊なページに対応</span><br>
                    スクロールに合わせて画像や文字が動くページ（視差効果・パララックス）や、<br class='d-none d-sm-block'>
                    ブラウザの幅に合わせてメイン画像がリサイズされるページフレックスサイズヘッダー）<br class='d-none d-sm-block'>
                    の時にお試しください。
                    </div>`;
        break;
      case 'support-lazy-load':
        messeger = `<div class="align-center-div text-left max-width-800px mt-2">
                    <span class='font-weight-bold'>●遅延読み込みに対応 </span><br>
                    画像を一度に読み込まず、スクロ一ルごとに必要な分だけ読み込むペ一ジの時にお試しください。
                    </div>`;
        break;
      default:
        break;
    }

    Popup.alert(messeger);
}
