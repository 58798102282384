import React from 'react';
import IconDelete from 'images/trash3.png'

const FileTable = ({ files, filledData, handleDeleteFile, handleUploadFiles }) => (
  <div className='show-list-table-list-files'>
    <div className='d-flex text-center justify-content-center align-items-center mt-2 mx-3 border-1px-black'>
      <div className='w-80'>
        <div className='text-center mb-2 text-title-of-table'>
          { files.length === 0 ? 'アップロード対象データ' : `アップロード対象データ（${files.length}件の読み込み完了済み）` }
        </div>
        <div className='show-table-files'>
          <table className='w-100 table table-bordered mb-1'>
            <thead className='bg-black w-100 text-white sticky-top'>
              <tr>
                <th width="6%">削除</th>
                <th>ファイル名</th>
                <th width="10%">容量</th>
              </tr>
            </thead>
            {
              files.length === 0 ? (
                <tbody className=''>
                  {[...Array(10)].map((_, index) => (
                    <tr key={index}><td></td><td></td><td></td></tr>
                  ))}
                </tbody>
              ) : (
                <tbody className=''>
                  {filledData.map((item, index) => (
                    <tr key={index}>
                      <td style={ !!item ? {padding: '2px'} : {}}>{item ? <img className='filter-black-icon cursor-pointer' width={15} src={IconDelete} onClick={() => handleDeleteFile(item, index)} /> : ''}</td>
                      <td className='text-left' style={ !!item ? {padding: '2px'} : {}}>{item?.name || ''}</td>
                      <td style={ !!item ? {padding: '2px'} : {}}>
                        {item?.size ? Shared.formatFileSize(item?.size, 1) : ''}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )
            }
          </table>
        </div>
        <div className='text-left small-text-input-file'>※対象データ表示前に<span className='font-weight-bold'>「Uploadする」</span>をクリックしても、アップロード対象は全てアップロードされます。</div>
      </div>
    </div>
    <div className='text-center create-akaire-box-content my-3'>
      <button type='button' className='btn btn-primary' onClick={(e) => handleUploadFiles(e) }>Uploadする</button>
    </div>
  </div>
);

export default FileTable;
