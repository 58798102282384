import React, { Component } from "react";
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Progress,
  Tooltip,
} from "reactstrap";
import apiClient from "../util/api-client";
import iconPcAndMobile from "../ui/images/iconPc_Mobile.svg";
import iconPc from "../ui/images/iconPc.svg";
import iconMobile from "../ui/images/iconMobile.svg";
import iconMobileSmall from "../ui/images/iconMobileSmall.svg";
import iconCam from "images/icon-www.png";

const regex = new RegExp("^(http[s]?:\\//){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");

const deviceOptions = [
  {
    icon: iconPcAndMobile,
    text: "表示サイズ",
    value: "",
    size: "",
    width: "43px",
    height: "24px",
  },
  {
    icon: iconPc,
    text: "デスクトップ大",
    value: "pc",
    size: "1920px",
    width: "30px",
    height: "26px",
  },
  {
    icon: iconPc,
    text: "デスクトップ中",
    value: "laptop",
    size: "1280px",
    width: "30px",
    height: "26px",
  },
  {
    icon: iconMobile,
    text: "タブレット",
    value: "tablet",
    size: "768px",
    width: "20px",
    height: "28px",
  },
  {
    icon: iconMobileSmall,
    text: "スマートフォン",
    value: "smartphone",
    size: "375px",
    width: "14px",
    height: "20px",
  },
];

const savePasswordOptions = [
  {
    text: "保存する",
    value: "save",
  },
  {
    text: "保存しない",
    value: "dont_save",
  }
];

export default class AnimationGetUrlModal extends Component {
  constructor(props) {
    super(props);
    const { projectId, baseURL, taskID } = props;
    this.state = {
      completed: 0,
      time: 0,
      showProgressBar: false,
      link: "",
      checked: false,
      toggleAdvancedSettings: true,
      toggleAccountLoginSettings: true,
      introductionIsOpen: false,
      introduceOpened: false,
      username: "",
      password: "",
      showBasicPassword: false,
      accUsername: "",
      accPassword: "",
      showAccPassword: false,
      supportSpecialPage: false,
      tooltipLazyPageOpen: false,
      supportLazyLoad: false,
      tooltipSpecialPageOpen: false,
      tooltipOptionSaveAccountOpen: false,
      tooltipAccountLogin: false,
      tooltipBasicAuthentication: false,
      deviceSelected: deviceOptions[0],
      savePasswordSelected: savePasswordOptions[0],
    };
    this.apiClient = apiClient({ baseURL: baseURL || "/" });
    this.intervalId = null;
  }

  calculateTime = () => {
    const intervalTime = this.state.supportSpecialPage || this.state.supportLazyLoad ? 610 : 420
    this.intervalId = setInterval(() => {
      const { completed } = this.state;

      if (completed < 99) {
        this.setState({ completed: completed + 1 });
      }
    }, intervalTime);
  };

  preprocess = () => {
    this.calculateTime();
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  hideIntroduceIdPassword() {
    apiClient({ baseURL: Routes.hide_popup_introduce_id_password_owner_users_path() })
      .post()
      .then((response) =>{})
      .catch(() => {
        Popup.alert("このページにアクセスできません。");
      })
  }

  getAccountByDomain() {
    apiClient({
      baseURL: Routes.fetch_account_akaire_page_owner_users_path({ link: this.state.link, task_id: this.props.taskID })
    })
      .post()
      .then((response) =>{
        if (response.data?.success) {
          const data = response.data
          this.setState({
            username: data.basic_id,
            password: data.basic_password,
            accUsername: data.acc_id,
            accPassword: data.acc_password,
            toggleAccountLoginSettings: true,
          })
        }
      })
      .catch(() => {
        Popup.alert("このページにアクセスできません。");
      })
  }

  checkedChange(event) {
    if (event.target.checked) {
      this.setState({
        checked: true,
      });
    } else {
      this.setState({
        checked: false,
        username: "",
        password: "",
      });
    }
  }

  closeModal = (event) => {
    const { onClickClose } = this.props;
    onClickClose();
    this.setState({
      checked: false,
      link: '',
      username: '',
      password: '',
      accUsername: '',
      accPassword: '',
      supportSpecialPage: false,
      supportLazyLoad: false
    });
  };

  finishProgress = () => {
    clearInterval(this.intervalId);
    this.intervalId = null;
    this.setState({ completed: 0, showProgressBar: false });
  };

  fromProjectId = () => {
    const url = new URL(location.href);
    return url.searchParams.get('from_project_id');
  };
  fromTaskId = () => {
    const url = new URL(location.href);
    return url.searchParams.get('from_task_id');
  }
  fromWorkId = () => {
    const url = new URL(location.href);
    return url.searchParams.get('from_work_id');
  }
  fromPageId = () => {
    const url = new URL(location.href);
    return url.searchParams.get('from_page_id');
  }

  handleSubmit = (event) => {
    this.preprocess();
    const { onFinish, onStart, checkAnimationLastPage, firstUploadAkaire, isCreateOfTemp } = this.props;
    const {
      link,
      username,
      password,
      accUsername,
      accPassword,
      supportSpecialPage,
      supportLazyLoad,
      deviceSelected,
      savePasswordSelected,
    } = this.state;

    event.preventDefault();

    if (regex.test(link)) {
      this.setState({ showProgressBar: true })
      const checkUploadNewPage = $('#uploadNewPagePopup').attr('data-upload-new') == 'true'
      const pageId = checkUploadNewPage ? 'new' : $('#uploadVersionPopup').attr('data-page-id')
      const data = {
        url: link,
        acc_username: accUsername,
        acc_password: accPassword,
        username: username,
        password: password,
        supportSpecialPage: supportSpecialPage,
        supportLazyLoad: supportLazyLoad,
        device: deviceSelected.value,
        save_password_auth: savePasswordSelected.value,
        page_id: pageId,
        from_project_id: this.fromProjectId(),
        from_task_id: this.fromTaskId(),
        from_work_id: this.fromWorkId(),
        from_page_id: this.fromPageId(),
      };
      if (pageId === 'new' || pageId == undefined){
        this.screenShotUrl(data)
      }else {
        this.apiClient.get(`${pageId}/check_final_version`).then((response) => {
          if(response.data.is_has_final_version){
            Popup.confirm('現在、このページには最終稿バージョンが存在します。<br>新しいバージョンをアップロードすると、最終稿が解除されます。<br>アップロードしてよろしいですか？', {
              confirmText: 'OK',
              cancelBtn: false,
              success: () => {
                this.screenShotUrl(data)
              },
            });
          }else{
            this.screenShotUrl(data)
          }
        })
        .catch(() => {
          Popup.alert("エラーが発生しました");;
        })
      }
    } else {
      this.finishProgress();
      Popup.alert("URLは不正な値です。");
    }
  };

  checkboxChange = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  screenShotUrl = (data) => {
    const { onFinish, onStart, firstUploadAkaire } = this.props;
    onStart();
    this.apiClient
      .post("/animations/screen_shot", data)
      .then((response) => {
        if (response.data?.success == true) {
          this.setState({
            link: "", deviceSelected: deviceOptions[0],
            savePasswordSelected: savePasswordOptions[0],
          });
          this.resetAdvancedSettings();
          this.resetAccountLoginSetting();
          this.closeModal();
          if(response.data?.new_animation) firstUploadAkaire()
          onFinish(true, response.data?.page_id)
        } else {
          if (response.data?.over_limit_page_login_number) {
            $("#overPageLoginURLModal").modal('show');
          } else {
            Popup.alert("現在、このURLは、ご利用できません。");
          }
          onFinish(false, response.data?.page_id)
        }
      })
      .catch(() => {
        Popup.alert("このページにアクセスできません。");
      })
      .finally((_) => {
        this.finishProgress();
      });
  }

  toggleAdvancedSettings = () => {
    if (!!this.state.toggleAdvancedSettings) {
      this.setState({ toggleAdvancedSettings: false });
    } else {
      this.resetAdvancedSettings();
    }
  };

  toggleAccountLoginSettings = () => {
    if (!!this.state.toggleAccountLoginSettings) {
      this.setState({ toggleAccountLoginSettings: false });
    } else {
      this.resetAccountLoginSetting();
    }
  };

  showIntroductionPopup = () => {
    if (this.state.introduceOpened) return;

    apiClient({ baseURL: Routes.check_show_popup_introduce_id_password_owner_users_path() })
      .post()
      .then((response) =>{
        this.setState({ introductionIsOpen: response.data })
      })
      .catch(() => {
        Popup.alert("このページにアクセスできません。");
      })
  }

  toggleIntroductionPopup = () => {
    this.setState({ introductionIsOpen: !this.state.introductionIsOpen, introduceOpened: true });
  }

  resetAdvancedSettings = () => {
    this.setState({
      toggleAdvancedSettings: true,
      supportSpecialPage: false,
      supportLazyLoad: false,
    });
  };

  resetAccountLoginSetting = () => {
    this.setState({
      toggleAccountLoginSettings: true,
      username: "",
      password: "",
      accUsername: "",
      accPassword: "",
      showBasicPassword: false,
      showAccPassword: false,
    });
  }

  handleSelectDevice = (value) => {
    let device = deviceOptions.filter((option) => option.value === value);
    device = device.length > 0 ? device[0] : deviceOptions[0];
    this.setState({ deviceSelected: device });
  };

  handleSelectSavePassword = (value) => {
    let option = savePasswordOptions.filter((option) => option.value === value);
    option = option.length > 0 ? option[0] : savePasswordOptions[0];
    this.setState({ savePasswordSelected: option })
  }

  toggleTooltipSpecialPage = () => {
    setTimeout(() => {
      this.setState({
        tooltipSpecialPageOpen: !this.state.tooltipSpecialPageOpen,
      });
    }, 0);
  };

  toggleTooltipOptionSaveAccount = () => {
    setTimeout(() => {
      this.setState({
        tooltipOptionSaveAccountOpen: !this.state.tooltipOptionSaveAccountOpen,
      });
    }, 0);
  };

  toggleTooltipBasicAuthentication = () => {
    setTimeout(() => {
      this.setState({
        tooltipBasicAuthentication: !this.state.tooltipBasicAuthentication,
      });
    }, 0);
  };

  toggleTooltipAccountLogin = () => {
    setTimeout(() => {
      this.setState({
        tooltipAccountLogin: !this.state.tooltipAccountLogin,
      });
    }, 0);
  };

  toggleTooltipLazyPage = () => {
    // if not setTimeout, tooltip on mobile will not hide after open
    // references: https://stackoverflow.com/a/49294377
    setTimeout(() => {
      this.setState({
        tooltipLazyPageOpen: !this.state.tooltipLazyPageOpen,
      });
    }, 0);
  };

  toggleBasicPassword = () => {
    this.setState({
      showBasicPassword: !this.state.showBasicPassword,
    })
  }

  toggleAccPassword = () => {
    this.setState({
      showAccPassword: !this.state.showAccPassword,
    })
  }

  redirectToCaptureMultipleLink = () => {
    const path = window.location.search
    window.location.href = window.location.origin + `/akaire_feature/akaires/capture_multiple_urls${path}`
  }

  showPopupCaptureUrl = (text) =>{
    const { isAkaponProject } = this.props;
    let messeger = '';
    var linkIdPass = `${gon.is_plan_akapon ? 'https://kanritools.com/support/#2-4-3-3' : 'https://createcloud.jp/support/#6-1-2-3'}`
    switch(text){
      case 'login-info':
        messeger = `<div class="align-center-div text-left max-width-650px mt-2">
                    <span class='font-weight-bold'>●ログイン情報</span><br>
                    会員サイトなど、ID・PASSのログイン情報が必要なページの場合、<br/>
                    ID｜PASS情報を入力すると、ログイン後のページ赤入れができます。<br/>
                    詳しくは<a href=${linkIdPass} target="_blank" class="text-underline text-white">こちら</a>をご覧ください。
                    </div>`;
        break;
      case 'basic-auth':
        messeger = `<div class="align-center-div text-left max-width-650px mt-2">
                    <span class="font-weight-bold">●ベーシック認証（Basic認証）とは</span><br>
                    HTTPで定義されてる認証方式の一つで、基本認証とも呼ばれています。<br/>
                    主に、テストサイトなどで使用される『ユーザーID・パスワード』です。
                    </div>`;
        break;
      case 'option-save-account':
        messeger = `<div class="align-center-div text-left max-width-1000px mt-2">
                    <span class='font-weight-bold'>【保存する】</span><br/>
                    次回以降、同じID｜PASSの「違う赤入れページ（新規）」を作成する時、「ID・PASS」が保存された状態になります。<br/>
                    ※他のメンバーには保存されません。<br/>
                    ※アカウント単位で異なるID・PASSを保存できます。<br/>
                    <br>
                    <span class='font-weight-bold'>【保存しない】</span><br/>
                    次回以降、同じID｜PASSの「違う赤入れページ（新規）」を作成する時、都度「ID・PASS」の入力が必要になります。
                    <br/>
                    ※ID・PASSにつきましては、会員様の責任のもと、管理をお願い致します。
                    </div>`;
        break;
      case 'support-special-page':
        messeger = `<div class="align-center-div text-left max-width-750px mt-2">
                    <span class='font-weight-bold'>●特殊なページに対応</span><br>
                    スクロールに合わせて画像や文字が動くページ（視差効果・パララックス）や、<br class='d-none d-sm-block'>
                    ブラウザの幅に合わせてメイン画像がリサイズされるページフレックスサイズヘッダー）<br class='d-none d-sm-block'>
                    の時にお試しください。
                    </div>`;
        break;
      case 'support-lazy-load':
        messeger = `<div class="align-center-div text-left max-width-800px mt-2">
                    <span class='font-weight-bold'>●遅延読み込みに対応 </span><br>
                    画像を一度に読み込まず、スクロ一ルごとに必要な分だけ読み込むペ一ジの時にお試しください。
                    </div>`;
        break;
      default:
        break;
    }

    Popup.alert(messeger);
  }

  render() {
    const { isOpen, onClickClose, isUploadMultipleFiles } = this.props;
    const {
      checked,
      link,
      deviceSelected,
      savePasswordSelected,
      toggleAdvancedSettings,
      toggleAccountLoginSettings,
      username,
      password,
      accUsername,
      accPassword,
      showBasicPassword,
      showAccPassword,
      supportSpecialPage,
      tooltipSpecialPageOpen,
      tooltipOptionSaveAccountOpen,
      tooltipAccountLogin,
      tooltipBasicAuthentication,
      supportLazyLoad,
      tooltipLazyPageOpen,
      introductionIsOpen,
    } = this.state;
    const { completed, showProgressBar } = this.state;
    const isBrowser = window.screen.width >= 768;
    let advancedSettings = "";
    let accountLoginSettings = "";

    var checkbox = "";
    var progressBar = "";
    var popupIntroduction = "";

    const unsubmittable = link.length === 0 || !regex.test(link);
    if (showProgressBar) {
      progressBar = (
        <Progress value={completed} className="mx-2">
          {completed}%
        </Progress>
      );
    }
    if (checked) {
      checkbox = (
        <div className="basic-authen">
          <div className="row align-items-center">
            <div className="col-3">ユーザーID</div>
            <div className="col-9">
              <Input
                type="text"
                placeholder="ユーザーID"
                name="username"
                autoComplete="username"
                onChange={(event) => this.handleChange(event)}
              />
            </div>
          </div>
          <div className="my-2 row align-items-center">
            <div className="col-3">パスワード</div>
            <div className="col-9">
              <Input
                type="password"
                placeholder="パスワード"
                name="password"
                autoComplete="password"
                onChange={(event) => this.handleChange(event)}
              />
            </div>
          </div>
        </div>
      );
    }

    if (toggleAccountLoginSettings) {
      accountLoginSettings = (
        <div className="d-flex align-items-center flex-wrap">
          <div className="w-65 w-sp-87">
            <div className="my-2 row">
              <div className="vl ml-3 w-100">
                <div className="ml-3">
                  <div className="font-weight-bold text-black fs-text-popup-url">
                    ログイン情報
                    <i className="fas fa-question-circle ml-2 mt-n1 scale-1_3 cursor-pointer" onClick={() => {this.showPopupCaptureUrl('login-info')}}></i>
                  </div>
                  <div className="row">
                    <div className="col-md-6 pr-0 col-sm-12">
                      <div className="d-flex w-100 align-items-center">
                        <Input
                          type="text"
                          placeholder="ID"
                          autoComplete="new-password"
                          name="accUsername"
                          onChange={(event) => this.handleChange(event)}
                          value={accUsername}
                          className="h-37px bg-seashell"
                        />
                      </div>
                    </div>
                    <div className={`col-md-6 pr-0 col-sm-12`}>
                      <div className="position-relative w-100">
                        <Input
                          type={showAccPassword ? "text" : "password"}
                          placeholder=" パスワード"
                          autoComplete="new-password"
                          name="accPassword"
                          onChange={(event) => this.handleChange(event)}
                          value={accPassword}
                          className="h-37px bg-seashell"
                        />
                        <div className="btn-toggle-password" onClick={() => this.toggleAccPassword()}>
                          <span className="btn-toggle-password-text">{showAccPassword ? "非表示" : "表示"}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="vl ml-3 mt-2 w-100">
                <div className="ml-3">
                  <div className="row">
                    <div className="col-8 font-weight-bold text-black fs-text-popup-url">
                      ベーシック認証
                      <i className="fas fa-question-circle ml-2 mt-n1 scale-1_3 cursor-pointer" onClick={() => {this.showPopupCaptureUrl('basic-auth')}}></i>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 pr-0 col-sm-12">
                      <div className="d-flex w-100 align-items-center">
                        <Input
                          type="text"
                          placeholder="ID"
                          autoComplete="new-password"
                          name="username"
                          onChange={(event) => this.handleChange(event)}
                          value={username}
                          className="h-37px bg-seashell"
                        />
                      </div>
                    </div>
                    <div className={`col-md-6 pr-0 col-sm-12`}>
                      <div className="position-relative w-100">
                        <Input
                          type={showBasicPassword ? "text" : "password"}
                          placeholder=" パスワード"
                          autoComplete="new-password"
                          name="password"
                          onChange={(event) => this.handleChange(event)}
                          value={password}
                          className="h-37px bg-seashell"
                        />
                        <div className="btn-toggle-password" onClick={() => this.toggleBasicPassword()}>
                          <span className="btn-toggle-password-text">{showBasicPassword ? "非表示" : "表示"}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dropdown-save-account pt-3">
            <div className="ml-4 pl-3 d-flex align-items-center">
              <div className="dropdown">
                <button
                  className="btn btn-dark-new font-weight-bold h-37px btn-choose-screen-size dropdown-toggle w-full"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  <div className="d-flex justify-content-between align-items-center text-white h-0">
                    <span className="">{savePasswordSelected.text}</span>
                    <i className="fa fa-chevron-down text-white ml-1"></i>
                  </div>
                </button>
                <ul className="dropdown-menu w-100 cursor-pointer bg-primary text-white">
                  {savePasswordOptions.map((option) => (
                    <li
                      className="d-flex align-items-center p-1 pl-3 row"
                      style={{ marginLeft: "0px", marginRight: "0px" }}
                      onClick={() => this.handleSelectSavePassword(option.value)}
                      key={option.value}
                    >
                      <div>
                        <span>{option.text}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <i className="fas fa-question-circle ml-3 scale-1_3 cursor-pointer" onClick={() => {this.showPopupCaptureUrl('option-save-account')}}></i>
            </div>
          </div>
        </div>
      );
    }

    if (toggleAdvancedSettings) {
      advancedSettings = (
        <div className="my-2 row w-100">
          <div className="vl ml-3 w-100">
            <div className="ml-3">
              <div className="bg-soft-peach p-3">
                <span className="font-weight-bold">追加オプション</span>
                <div className="ml-3 mt-2 option-screen-capture">
                  <Input
                    type="checkbox"
                    name="supportSpecialPage"
                    onChange={(event) => this.checkboxChange(event)}
                    className="scale-1_5"
                    id="supportSpecialPage"
                    checked={supportSpecialPage}
                  />
                  <label
                    htmlFor="supportSpecialPage"
                    className="font-weight-bold"
                  >
                    {" "}
                    特殊なページに対応
                  </label>
                  <i
                    className="fas fa-question-circle ml-3 scale-1_3 cursor-pointer"
                    onClick={() => {this.showPopupCaptureUrl('support-special-page')}}
                  ></i>
                </div>
                <div className="ml-3 option-screen-capture">
                  <Input
                    type="checkbox"
                    name="supportLazyLoad"
                    onChange={(event) => this.checkboxChange(event)}
                    className="scale-1_5"
                    id="supportLazyLoad"
                    checked={supportLazyLoad}
                  />
                  <label htmlFor="supportLazyLoad" className="font-weight-bold">
                    {" "}
                    遅延読み込みに対応
                  </label>
                  <i
                    className="fas fa-question-circle ml-3 scale-1_3 cursor-pointer"
                    onClick={() => {this.showPopupCaptureUrl('support-lazy-load')}}
                  ></i>
                </div>
                <div className="ml-3">
                  <p className="small text-muted">
                    CSSやJavaScriptの影響でうまく表示されないときにお試しください。
                    <br />
                    ただしキャプチャにしばらく時間がかかります。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    popupIntroduction = (
      <Modal
        isOpen={introductionIsOpen}
        toggle={() => this.toggleIntroductionPopup()}
        className="modal-dialog-centered w-fit-content m-auto modal-style modal-capture-image-url"
        modalClassName="modal-second"
        contentClassName="border-radius-10px"
        onClick={() => this.toggleIntroductionPopup() }
      >
        <div className="fs-sp-12 p-4 fs-popup-click-input-url mx-auto">
          <span>
            ログイン情報が必要なサイトのURLを入力する場合、
            <br/>
            赤入れしたいページのURLを入力してください。
          </span>
          <div className="text-right mt-2">
            <Input type="checkbox" onChange={() => this.hideIntroduceIdPassword()} className="mr-1 d-inline"/>
            <label className="mb-0">今後表示させない</label>
          </div>
        </div>
      </Modal>
    )

    return (
      <Modal
        isOpen={isOpen}
        className="animation-public-url-modal modal-capture-image-url modal-dialog-centered modal-style"
        toggle={this.closeModal}
        size="lg"
        style={{ maxWidth: "704px" }}
      >
        <form onSubmit={this.handleSubmit}>
          <ModalBody onProgress={this.onCaptureProgress} className="pb-0">
            <div className='justify-content-center text-center'>
              <span className='additional-akaire-title'>
                <img className="icon-upload" src={iconCam} alt="アップロード" width="50" />
                WEBサイトのURLを赤入れする</span>
                {progressBar}
            </div>
            <div className="align-center-div">
              <div className="row pt-3">
                <div
                  className={`col-md-7 col-sm-12 ${
                    isBrowser
                      ? "border-right-radius-none pr-0"
                      : "border-bottom-radius-none"
                  }`}
                >
                  <Input
                    type="text"
                    placeholder="ここにURLを入力"
                    name="link"
                    autoComplete="off"
                    onFocus={() => this.showIntroductionPopup()}
                    onChange={(event) => this.handleChange(event)}
                    onBlur={() => this.getAccountByDomain()}
                    value={link}
                    className={`bg-input-capture-url ${isBrowser && "border-right-0"}`}
                  />
                </div>

                <div className={`col-md-5 col-sm-12 ${isBrowser && "pl-0"}`}>
                  <div className="dropdown">
                    <button
                      className="btn btn-default btn-choose-screen-size dropdown-toggle dropdown-with-image bg-soft-peach"
                      type="button"
                      id="selectDevices"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true"
                    >
                      <div className="row d-flex align-items-center">
                        <div
                          className="col-3 text-center"
                          style={{ padding: "0px" }}
                        >
                          <img
                            src={deviceSelected.icon}
                            style={{ width: deviceSelected.width, height: deviceSelected.height }}
                          />
                        </div>
                        <div className="col-9">
                          <div className="row">
                            <div className="col-9 text-left font-weight-bold">
                              <span>{deviceSelected.text}</span>
                              <span
                                className={
                                  deviceSelected.size === "768px"
                                    ? "ml-4 pl-3"
                                    : "ml-2"
                                }
                              >
                                {deviceSelected.size}
                              </span>
                            </div>
                            <div className="col-1 text-right">
                              <i className="fa fa-chevron-down text-muted"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </button>
                    <ul className="dropdown-menu w-100 cursor-pointer">
                      {deviceOptions.map((option) => (
                        <li
                          className="d-flex align-items-center p-1 row"
                          style={{ marginLeft: "0px", marginRight: "0px" }}
                          onClick={() => this.handleSelectDevice(option.value)}
                          key={option.value}
                        >
                          <div
                            className="col-3 text-center"
                            style={{ paddingRight: "0px", paddingLeft: "0px" }}
                          >
                            <img
                              src={option.icon}
                              style={{ width: option.width, height: option.height }}
                            />
                          </div>
                          <div
                            className="col-9"
                            style={{ paddingRight: "0px", paddingLeft: "0px" }}
                          >
                            <span>{option.text}</span>
                            <span
                              className={
                                option.size === "768px" ? "ml-4 pl-3" : "ml-2"
                              }
                            >
                              {option.size}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              { popupIntroduction }
              { !!isUploadMultipleFiles ? (<div className="my-3">
                  <div className="row">
                    <div className="col-12 font-weight-bold cursor-pointer fs-title-popup-url" onClick={this.redirectToCaptureMultipleLink}>
                      WEBサイトのURLをまとめてキャプチャする
                    </div>
                  </div>
                </div>) : ('')
              }

              <div className="my-3">
                <div className="row">
                  <div
                    className="col-md-7 col-12 font-weight-bold cursor-pointer fs-title-popup-url"
                    onClick={() => this.toggleAccountLoginSettings()}
                  >
                    <i
                      className={`pr-2 text-muted fa fa-chevron-${
                        toggleAccountLoginSettings ? "up" : "down"
                      }`}
                    ></i>
                    ID・PASSが必要なサイト
                  </div>
                </div>

                {accountLoginSettings}
              </div>

              <div className="my-3">
                <div className="row">
                  <div
                    className="col-md-9 col-12 font-weight-bold cursor-pointer fs-title-popup-url"
                    onClick={() => this.toggleAdvancedSettings()}
                  >
                    <i
                      className={`pr-2 text-muted fa fa-chevron-${
                        toggleAdvancedSettings ? "up" : "down"
                      }`}
                    ></i>
                    高度なキャプチャ設定が必要なサイト
                  </div>
                </div>

                {advancedSettings}
              </div>
            </div>
          </ModalBody>

          <ModalFooter className={`${isBrowser && "w-95 pt-0 ml-3"}`}>
            <div
              className={`w-100 additional-akaire-button ${
                isBrowser
                  ? "d-flex justify-content-center align-items-center flex-gap-10"
                  : "row text-center mr-0 mb-3 gap-12 justify-content-center"
              }`}
            >
              <div>
                <Button
                  className={`bg-dark ${
                    isBrowser && "d-flex align-items-center text-white px-4 py-2"
                  } ${unsubmittable && 'opacity-0_5'}`}
                  color="secondary"
                  disabled={unsubmittable}
                >
                  <span>キャプチャする</span>
                </Button>
              </div>
              <div>
                <Button
                  className={`btn-button-close ${
                    isBrowser && "d-flex align-items-center px-4 py-2"
                  }`}
                  onClick={(event) => this.closeModal(event)}
                >
                  <span>閉じる</span>
                </Button>
              </div>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}
