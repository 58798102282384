/**
 * File generated by js-routes 2.1.2
 * Based on Rails 6.0.4.1 routes of PlabAdminRor::Application
 */
const __jsr = ((that) => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const Root = that;
    const isBroswer = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define(routes) {
                Utils.namespace(Root, null, routes);
            },
            isSupported() {
                return !!Root;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {};
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            const query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBroswer && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        namespace(object, namespace, routes) {
            const parts = (namespace === null || namespace === void 0 ? void 0 : namespace.split(".")) || [];
            if (parts.length === 0) {
                return routes;
            }
            for (let index = 0; index < parts.length; index++) {
                const part = parts[index];
                if (index < parts.length - 1) {
                    object = object[part] || (object[part] = {});
                }
                else {
                    return (object[part] = routes);
                }
            }
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})(this);
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /request_admin_to_invite_members/:id/accept(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_owner_request_admin_to_invite_member_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"request_admin_to_invite_members"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"accept"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /p/animations/:id/access_password(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const access_password_animation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"access_password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:id/access_password_share(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const access_password_share_object_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"access_password_share"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /orders/activate/:subscription_id(.:format)
 * @param {any} subscription_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activate_path = __jsr.r({"subscription_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"activate"],[2,[7,"/"],[2,[3,"subscription_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/add_person_incharge(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_person_incharge_owner_project_tasks_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[6,"add_person_incharge"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/:type/downloads(.:format)
 * @param {any} type
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_download_admins_companies_path = __jsr.r({"type":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"type"],[2,[7,"/"],[2,[6,"downloads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/admins/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_admin_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/campaigns/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_campaign_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/campaigns(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_campaigns_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"campaigns"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/company/:company_id(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_companies_company_change_status_company_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[3,"company_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/company/:company_id/:type_detail(.:format)
 * @param {any} company_id
 * @param {any} type_detail
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_companies_company_detail_path = __jsr.r({"company_id":{"r":true},"type_detail":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[3,"type_detail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/company/:company_id/sign_in(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_companies_company_sign_in_member_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/:company_id/add_agency(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_company_add_agency_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"add_agency"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/:company_id/change_status_subscription(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_company_change_status_subscription_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"change_status_subscription"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/:company_id/connect_company(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_company_connect_company_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"connect_company"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/:company_id/edit_owner_dedicated_url(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_company_edit_owner_dedicated_url_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"edit_owner_dedicated_url"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/:company_id/invoices/:id(.:format)
 * @param {any} company_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_company_invoice_path = __jsr.r({"company_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/:company_id/rollback_paid_subcription(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_company_rollback_paid_subcription_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"rollback_paid_subcription"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/:company_id/show_subscription(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_company_show_subscription_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"show_subscription"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/:company_id/subscriptions(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_company_subscriptions_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/delay_payments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_delay_payment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"delay_payments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/delay_payments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_delay_payments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"delay_payments"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/member_stores/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_member_store_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"member_stores"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/member_stores(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_member_stores_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"member_stores"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/news/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_notice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/news(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_notices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"news"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/payments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_payments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"payments"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/privacy_and_terms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_privacy_and_term_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"privacy_and_terms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/privacy_and_terms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_privacy_and_terms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"privacy_and_terms"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/render_account_for_owner(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_render_account_owner_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"render_account_for_owner"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/render_account_for_supplier(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_render_account_supplier_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"render_account_for_supplier"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_sidekiq_web_path = __jsr.r({}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[6,"sidekiq"]]]]);

/**
 * Generates rails route to
 * /akaire_file/:object_id/change_status(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_change_status_akaire_file_index_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"change_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaires/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_akaire_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaires"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_akaire_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/:id(.:format)
 * @param {any} akaire_file_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_akaire_file_animation_path = __jsr.r({"akaire_file_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/:animation_id/animation_comments/:id(.:format)
 * @param {any} akaire_file_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_akaire_file_animation_animation_comment_path = __jsr.r({"akaire_file_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/:animation_id/animation_comments(.:format)
 * @param {any} akaire_file_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_akaire_file_animation_animation_comments_path = __jsr.r({"akaire_file_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/:animation_id/animation_markers/:id(.:format)
 * @param {any} akaire_file_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_akaire_file_animation_animation_marker_path = __jsr.r({"akaire_file_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/:animation_id/animation_markers(.:format)
 * @param {any} akaire_file_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_akaire_file_animation_animation_markers_path = __jsr.r({"akaire_file_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/:animation_id/extension_files(.:format)
 * @param {any} akaire_file_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_akaire_file_animation_extension_files_path = __jsr.r({"akaire_file_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/:animation_id/finalization(.:format)
 * @param {any} akaire_file_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_akaire_file_animation_finalization_path = __jsr.r({"akaire_file_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations(.:format)
 * @param {any} akaire_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_akaire_file_animations_path = __jsr.r({"akaire_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/pages/:id(.:format)
 * @param {any} akaire_file_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_akaire_file_page_path = __jsr.r({"akaire_file_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/pages/:page_id/animations/:id(.:format)
 * @param {any} akaire_file_id
 * @param {any} page_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_akaire_file_page_animation_path = __jsr.r({"akaire_file_id":{"r":true},"page_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/pages/:page_id/animations(.:format)
 * @param {any} akaire_file_id
 * @param {any} page_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_akaire_file_page_animations_path = __jsr.r({"akaire_file_id":{"r":true},"page_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/pages(.:format)
 * @param {any} akaire_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_akaire_file_pages_path = __jsr.r({"akaire_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"pages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/users(.:format)
 * @param {any} akaire_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_akaire_file_users_path = __jsr.r({"akaire_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaires(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_akaires_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaires"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/animation_markers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_animation_marker_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"animation_markers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/animation_markers/:animation_marker_id/reply_comments/:id(.:format)
 * @param {any} animation_marker_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_animation_marker_reply_comment_path = __jsr.r({"animation_marker_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"animation_markers"],[2,[7,"/"],[2,[3,"animation_marker_id"],[2,[7,"/"],[2,[6,"reply_comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/animation_markers/:animation_marker_id/reply_comments(.:format)
 * @param {any} animation_marker_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_animation_marker_reply_comments_path = __jsr.r({"animation_marker_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"animation_markers"],[2,[7,"/"],[2,[3,"animation_marker_id"],[2,[7,"/"],[2,[6,"reply_comments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/:id(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_animation_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/:animation_id/animation_comments/:id(.:format)
 * @param {any} project_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_animation_animation_comment_path = __jsr.r({"project_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/:animation_id/animation_comments(.:format)
 * @param {any} project_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_animation_animation_comments_path = __jsr.r({"project_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/:animation_id/animation_markers/:id(.:format)
 * @param {any} project_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_animation_animation_marker_path = __jsr.r({"project_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/:animation_id/animation_markers(.:format)
 * @param {any} project_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_animation_animation_markers_path = __jsr.r({"project_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/:animation_id/extension_files(.:format)
 * @param {any} project_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_animation_extension_files_path = __jsr.r({"project_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/:animation_id/finalization(.:format)
 * @param {any} project_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_animation_finalization_path = __jsr.r({"project_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_animations_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/pages/:id(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_page_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/pages/:page_id/animations/:id(.:format)
 * @param {any} project_id
 * @param {any} page_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_page_animation_path = __jsr.r({"project_id":{"r":true},"page_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/pages/:page_id/animations(.:format)
 * @param {any} project_id
 * @param {any} page_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_page_animations_path = __jsr.r({"project_id":{"r":true},"page_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/pages(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_pages_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"pages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/:id/akaire/new(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"akaire"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/:id(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_animation_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/:animation_id/animation_comments/:id(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_animation_animation_comment_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/:animation_id/animation_comments(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_animation_animation_comments_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/:animation_id/animation_markers/:id(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_animation_animation_marker_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/:animation_id/animation_markers(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_animation_animation_markers_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/:animation_id/extension_files(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_animation_extension_files_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/:animation_id/finalization(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_animation_finalization_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/pages/:id(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_page_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/pages/:page_id/animations/:id(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} page_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_page_animation_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"page_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/pages/:page_id/animations(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} page_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_page_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"page_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/pages(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_pages_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"pages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:id(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/:id(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_ticket_animation_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/:animation_id/animation_comments/:id(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_ticket_animation_animation_comment_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/:animation_id/animation_comments(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_ticket_animation_animation_comments_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/:animation_id/animation_markers/:id(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_ticket_animation_animation_marker_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/:animation_id/animation_markers(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_ticket_animation_animation_markers_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/:animation_id/extension_files(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_ticket_animation_extension_files_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/:animation_id/finalization(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_ticket_animation_finalization_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_ticket_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/pages/:id(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_ticket_page_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/pages/:page_id/animations/:id(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} page_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_ticket_page_animation_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"page_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/pages/:page_id/animations(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} page_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_ticket_page_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"page_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/pages(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_ticket_pages_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"pages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/user/:id(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_ticket_user_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/user(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_ticket_user_index_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_tickets_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/user/:id(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_user_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/user(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_task_user_index_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/:task_id/:id/akaire/new(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"akaire"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:id/comment_members(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_ticket_comment_members_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"comment_members"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:id/tickets_data(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_ticket_data_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tickets_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:id/uploaded_data_info(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_ticket_data_info_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"uploaded_data_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/user/:id(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_user_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/user(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_feature_project_user_index_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/:object_id/project_akaire_files(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_file_project_akaire_file_index_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"project_akaire_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/:object_id/task_akaire_files(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_file_task_akaire_file_index_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"task_akaire_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/:object_id/work_akaire_files(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_file_work_akaire_file_index_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"work_akaire_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/:id/akaire/:akaire_id/:page_id/:version_id(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {any} akaire_id
 * @param {any} page_id
 * @param {any} version_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_project_task_new_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"akaire_id":{"r":true},"page_id":{"r":true},"version_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"akaire"],[2,[7,"/"],[2,[3,"akaire_id"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[3,"version_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/:task_id/:id/akaire/:akaire_id/:page_id/:version_id(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} id
 * @param {any} akaire_id
 * @param {any} page_id
 * @param {any} version_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const akaire_project_ticket_new_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"id":{"r":true},"akaire_id":{"r":true},"page_id":{"r":true},"version_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"akaire"],[2,[7,"/"],[2,[3,"akaire_id"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[3,"version_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /company_ip_limits/allowed_ip_addresses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const allowed_ip_addresses_owner_company_ip_limits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company_ip_limits"],[2,[7,"/"],[2,[6,"allowed_ip_addresses"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /p/animations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const animation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaires/animations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const animation_create_akaire_feature_akaires_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaires"],[2,[7,"/"],[2,[6,"animations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaires/animations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const animation_new_akaire_feature_akaires_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaires"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaires/animations/check_status_convert_pdf(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const animations_check_status_convert_pdf_akaire_feature_akaires_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaires"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"check_status_convert_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaires/animations/convert_pdf(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const animations_convert_pdf_akaire_feature_akaires_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaires"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"convert_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaires/animations/save_animation_embed(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const animations_save_animation_embed_akaire_feature_akaires_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaires"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"save_animation_embed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaires/animations/screen_shot(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const animations_screen_shot_akaire_feature_akaires_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaires"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"screen_shot"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /orders/approval(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approval_owner_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"approval"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id/approval(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approval_owner_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approval"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /approval_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approval_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"approval_settings"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /orders/approval(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approval_supplier_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"approval"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/:id/approve_member_store(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approve_member_store_admins_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approve_member_store"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaires/assign_animation_to_akaire_page(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assign_animation_to_akaire_page_akaire_feature_akaires_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaires"],[2,[7,"/"],[2,[6,"assign_animation_to_akaire_page"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/aws_callback(.:format)
 * @param {any} akaire_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const aws_callback_akaire_feature_akaire_file_animations_path = __jsr.r({"akaire_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"aws_callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/aws_callback(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const aws_callback_akaire_feature_project_animations_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"aws_callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/aws_callback(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const aws_callback_akaire_feature_project_task_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"aws_callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/aws_callback(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const aws_callback_akaire_feature_project_task_ticket_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"aws_callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/aws_callback(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const aws_callback_object_animations_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"aws_callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/aws_callback(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const aws_callback_object_ticket_animations_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"aws_callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /collaborators/:id/be_invited(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const be_invited_owner_collaborator_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"be_invited"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /unread/bookmark(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bookmark_owner_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[2,[7,"/"],[2,[6,"bookmark"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /unread/bookmark(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bookmark_supplier_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[2,[7,"/"],[2,[6,"bookmark"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member_stores/campaign_detail(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const campaign_detail_owner_member_stores_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member_stores"],[2,[7,"/"],[2,[6,"campaign_detail"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orders/cancel/:subscription_id(.:format)
 * @param {any} subscription_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_path = __jsr.r({"subscription_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"cancel"],[2,[7,"/"],[2,[3,"subscription_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/:animation_id/finalization/cancel_finalize_animation(.:format)
 * @param {any} akaire_file_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_finalize_animation_akaire_feature_akaire_file_animation_finalization_path = __jsr.r({"akaire_file_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[2,[7,"/"],[2,[6,"cancel_finalize_animation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/:animation_id/finalization/cancel_finalize_animation(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_finalize_animation_akaire_feature_project_task_animation_finalization_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[2,[7,"/"],[2,[6,"cancel_finalize_animation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/:animation_id/finalization/cancel_finalize_animation(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_finalize_animation_akaire_feature_project_task_ticket_animation_finalization_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[2,[7,"/"],[2,[6,"cancel_finalize_animation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/:animation_id/finalization/cancel_finalize_animation(.:format)
 * @param {any} object_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_finalize_animation_object_animation_finalization_path = __jsr.r({"object_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[2,[7,"/"],[2,[6,"cancel_finalize_animation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/:animation_id/finalization/cancel_finalize_animation(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_finalize_animation_object_ticket_animation_finalization_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[2,[7,"/"],[2,[6,"cancel_finalize_animation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /efforts/cancel_rejected_work(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_rejected_work_efforts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"efforts"],[2,[7,"/"],[2,[6,"cancel_rejected_work"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaires/capture_multiple_urls(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capture_multiple_urls_akaire_feature_akaires_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaires"],[2,[7,"/"],[2,[6,"capture_multiple_urls"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/:akaire_file_id/change_akaire_file_name(.:format)
 * @param {any} akaire_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_akaire_file_name_akaire_file_index_path = __jsr.r({"akaire_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"change_akaire_file_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collaborators/:id/change_collaborator_alias_name(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_collaborator_alias_name_owner_collaborator_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"change_collaborator_alias_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/:object_id/change_dateline(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_dateline_for_akaire_file_index_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"change_dateline"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/change_dateline_of_task(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_dateline_of_task_owner_project_tasks_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[6,"change_dateline_of_task"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/check_list_work/change_name(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_name_owner_project_check_list_work_index_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"check_list_work"],[2,[7,"/"],[2,[6,"change_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/change_password_share_url(.:format)
 * @param {any} akaire_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_password_share_url_akaire_feature_akaire_file_animations_path = __jsr.r({"akaire_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"change_password_share_url"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/change_password_share_url(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_password_share_url_akaire_feature_project_task_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"change_password_share_url"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/change_password_share_url(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_password_share_url_akaire_feature_project_task_ticket_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"change_password_share_url"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/change_password_share_video(.:format)
 * @param {any} akaire_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_password_share_video_akaire_feature_akaire_file_animations_path = __jsr.r({"akaire_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"change_password_share_video"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/change_password_share_video(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_password_share_video_akaire_feature_project_task_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"change_password_share_video"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/change_password_share_video(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_password_share_video_akaire_feature_project_task_ticket_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"change_password_share_video"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/change_position_akaire_file(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_position_akaire_file_akaire_file_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[6,"change_position_akaire_file"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /company/change_private_domain(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_private_domain_owner_company_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"change_private_domain"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/change_show_popup_confirm_akaire_file(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_show_popup_confirm_akaire_file_akaire_file_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[6,"change_show_popup_confirm_akaire_file"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/check_list_work/change_status_check_list(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_status_check_list_owner_project_check_list_work_index_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"check_list_work"],[2,[7,"/"],[2,[6,"change_status_check_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /efforts/change_status_efforts_options(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_status_efforts_options_efforts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"efforts"],[2,[7,"/"],[2,[6,"change_status_efforts_options"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /discussions/change_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_status_owner_discussions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"discussions"],[2,[7,"/"],[2,[6,"change_status"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/change_status_quantity(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_status_quantity_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"change_status_quantity"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id/change_supplier_name(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_supplier_name_owner_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"change_supplier_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/:akaire_file_id/change_type_akaire(.:format)
 * @param {any} akaire_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_type_akaire_akaire_file_index_path = __jsr.r({"akaire_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"change_type_akaire"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/:akaire_file_id/chat(.:format)
 * @param {any} akaire_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chat_akaire_file_akaire_file_index_path = __jsr.r({"akaire_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"chat"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/check_add_permission_to_task(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_add_permission_to_task_owner_project_tasks_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[6,"check_add_permission_to_task"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/check_add_permission_to_task_with_list_memeber(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_add_permission_to_task_with_list_memeber_owner_project_tasks_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[6,"check_add_permission_to_task_with_list_memeber"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/pages/:id/check_animation(.:format)
 * @param {any} akaire_file_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_animation_akaire_feature_akaire_file_page_path = __jsr.r({"akaire_file_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_animation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/pages/:id/check_animation(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_animation_akaire_feature_project_task_page_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_animation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/pages/:id/check_animation(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_animation_akaire_feature_project_task_ticket_page_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_animation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/:id/check_blance_amount(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_blance_amount_owner_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_blance_amount"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/:id/check_blance_amount(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_blance_amount_supplier_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_blance_amount"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /subscriptions/check_can_payment_to_paid(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_can_payment_to_paid_owner_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"check_can_payment_to_paid"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /subscriptions/check_cancel_company(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_cancel_company_owner_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"check_cancel_company"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /campaigns/check_code(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_code_owner_campaigns_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[6,"check_code"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cards/check_confirm_setup_intents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_confirm_setup_intents_owner_cards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[6,"check_confirm_setup_intents"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/check_delete_company(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_delete_company_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"check_delete_company"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/check_delete_permission_for_new_task(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_delete_permission_for_new_task_owner_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_delete_permission_for_new_task"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/check_delete_work_quantity(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_delete_work_quantity_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"check_delete_work_quantity"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/check_destroy_client(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_destroy_client_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"check_destroy_client"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /companies/check_destroy_collaborator(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_destroy_collaborator_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"check_destroy_collaborator"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /companies/check_destroy_supplier(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_destroy_supplier_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"check_destroy_supplier"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/check_effort_release(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_effort_release_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"check_effort_release"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/check_effort_release_with_delete_ticket(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_effort_release_with_delete_ticket_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"check_effort_release_with_delete_ticket"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/:animation_id/extension_files/check_exists(.:format)
 * @param {any} akaire_file_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_exists_akaire_feature_akaire_file_animation_extension_files_path = __jsr.r({"akaire_file_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[2,[7,"/"],[2,[6,"check_exists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/:animation_id/extension_files/check_exists(.:format)
 * @param {any} project_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_exists_akaire_feature_project_animation_extension_files_path = __jsr.r({"project_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[2,[7,"/"],[2,[6,"check_exists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/:animation_id/extension_files/check_exists(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_exists_akaire_feature_project_task_animation_extension_files_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[2,[7,"/"],[2,[6,"check_exists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/:animation_id/extension_files/check_exists(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_exists_akaire_feature_project_task_ticket_animation_extension_files_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[2,[7,"/"],[2,[6,"check_exists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/:animation_id/extension_files/check_exists(.:format)
 * @param {any} object_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_exists_object_animation_extension_files_path = __jsr.r({"object_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[2,[7,"/"],[2,[6,"check_exists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/:animation_id/extension_files/check_exists(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_exists_object_ticket_animation_extension_files_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[2,[7,"/"],[2,[6,"check_exists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:id/:page_id/check_final_version(.:format)
 * @param {any} id
 * @param {any} page_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_final_version_akaire_feature_akaire_file_path = __jsr.r({"id":{"r":true},"page_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"check_final_version"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/check_member_before_delete_permission(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_member_before_delete_permission_owner_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_member_before_delete_permission"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/check_member_before_delete_pic(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_member_before_delete_pic_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"check_member_before_delete_pic"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/check_member_of_partner(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_member_of_partner_owner_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_member_of_partner"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/check_much_quantity(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_much_quantity_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"check_much_quantity"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/check_much_quantity(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_much_quantity_supplier_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"check_much_quantity"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:id/check_password_share(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_password_share_object_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_password_share"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/animations/:id/check_password_video(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_password_video_animation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_password_video"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/check_permission_before_add(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_permission_before_add_owner_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_permission_before_add"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/check_quantity_present(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_quantity_present_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"check_quantity_present"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /efforts/check_release_effort_quotation_expiration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_release_effort_quotation_expiration_efforts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"efforts"],[2,[7,"/"],[2,[6,"check_release_effort_quotation_expiration"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/check_show_popup_change_akaire_file(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_show_popup_change_akaire_file_akaire_file_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[6,"check_show_popup_change_akaire_file"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/check_show_popup_introduce_id_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_show_popup_introduce_id_password_owner_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"check_show_popup_introduce_id_password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/check_status_convert_pdf(.:format)
 * @param {any} akaire_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_status_convert_pdf_akaire_feature_akaire_file_animations_path = __jsr.r({"akaire_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"check_status_convert_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaires/check_status_convert_pdf(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_status_convert_pdf_akaire_feature_akaires_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaires"],[2,[7,"/"],[2,[6,"check_status_convert_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/check_status_convert_pdf(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_status_convert_pdf_akaire_feature_project_task_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"check_status_convert_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/check_status_convert_pdf(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_status_convert_pdf_akaire_feature_project_task_ticket_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"check_status_convert_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/check_ticket_effort_not_release(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_ticket_effort_not_release_owner_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_ticket_effort_not_release"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/check_user_belong_order(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_user_belong_order_owner_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_user_belong_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/payment_term_revisions/check_validation(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_validation_owner_payment_term_revisions_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"payment_term_revisions"],[2,[7,"/"],[2,[6,"check_validation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/:id/payment_term_revisions/check_validation(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_validation_supplier_payment_term_revisions_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"payment_term_revisions"],[2,[7,"/"],[2,[6,"check_validation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/check_list_work/check_work_check_list(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_work_check_list_owner_project_check_list_work_index_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"check_list_work"],[2,[7,"/"],[2,[6,"check_work_check_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ckeditor
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ckeditor_path = __jsr.r({}, [2,[7,"/"],[6,"ckeditor"]]);

/**
 * Generates rails route to
 * /ckeditor/pictures(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ckeditor_pictures_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"ckeditor"]],[7,"/"]],[2,[6,"pictures"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ckeditor/pictures/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ckeditor_picture_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"ckeditor"]],[7,"/"]],[2,[6,"pictures"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ckeditor/attachment_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ckeditor_attachment_files_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"ckeditor"]],[7,"/"]],[2,[6,"attachment_files"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ckeditor/attachment_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ckeditor_attachment_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"ckeditor"]],[7,"/"]],[2,[6,"attachment_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /client/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_client_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"client"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /collaborators/:id/manager(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const collab_person_in_charges_owner_collaborator_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"manager"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collaborators/:id/profile(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const collab_profile_owner_collaborator_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"profile"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:id/comment_members(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const comment_members_akaire_feature_akaire_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"comment_members"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:id/comment_members(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const comment_members_akaire_feature_project_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"comment_members"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:id/comment_members(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const comment_members_akaire_feature_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"comment_members"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:id/comment_members(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const comment_members_object_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"comment_members"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /unread/company(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const company_owner_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[2,[7,"/"],[2,[6,"company"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /unread/company(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const company_supplier_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[2,[7,"/"],[2,[6,"company"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:id/compare_version(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compare_version_akaire_feature_akaire_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"compare_version"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/:id/confirmed(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirmed_owner_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"confirmed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id/contract/info(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contract_owner_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contract"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/convert_mp4(.:format)
 * @param {any} akaire_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const convert_mp4_akaire_feature_akaire_file_animations_path = __jsr.r({"akaire_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"convert_mp4"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaires/convert_mp4(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const convert_mp4_akaire_feature_akaires_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaires"],[2,[7,"/"],[2,[6,"convert_mp4"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/convert_mp4(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const convert_mp4_akaire_feature_project_task_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"convert_mp4"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/convert_mp4(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const convert_mp4_akaire_feature_project_task_ticket_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"convert_mp4"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/convert_mp4(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const convert_mp4_object_animations_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"convert_mp4"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/convert_mp4(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const convert_mp4_object_ticket_animations_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"convert_mp4"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/convert_pdf(.:format)
 * @param {any} akaire_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const convert_pdf_akaire_feature_akaire_file_animations_path = __jsr.r({"akaire_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"convert_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaires/convert_pdf(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const convert_pdf_akaire_feature_akaires_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaires"],[2,[7,"/"],[2,[6,"convert_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/convert_pdf(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const convert_pdf_akaire_feature_project_animations_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"convert_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/convert_pdf(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const convert_pdf_akaire_feature_project_task_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"convert_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/convert_pdf(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const convert_pdf_akaire_feature_project_task_ticket_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"convert_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/convert_pdf(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const convert_pdf_object_animations_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"convert_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/convert_pdf(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const convert_pdf_object_ticket_animations_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"convert_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/:akaire_file_id/copy_akaire_file(.:format)
 * @param {any} akaire_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_akaire_file_akaire_file_index_path = __jsr.r({"akaire_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"copy_akaire_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /orders/copy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_owner_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /unread/create_bookmark(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_bookmark_owner_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[2,[7,"/"],[2,[6,"create_bookmark"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /unread/create_bookmark(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_bookmark_supplier_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[2,[7,"/"],[2,[6,"create_bookmark"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/clients/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_client_admins_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/create_from_project_list(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_from_project_list_owner_project_tasks_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[6,"create_from_project_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:id/create_order(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_order_owner_quotation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /option/contract(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_owner_pages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"option"],[2,[7,"/"],[2,[6,"contract"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /collaborators/:id/create_pic(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_pic_owner_collaborator_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_pic"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/create_task_and_transit_akaire(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_task_and_transit_akaire_owner_projects_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[6,"create_task_and_transit_akaire"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /unread/create_unread(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_unread_owner_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[2,[7,"/"],[2,[6,"create_unread"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /unread/create_unread(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_unread_supplier_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[2,[7,"/"],[2,[6,"create_unread"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /quotations/data_on_basic_info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_on_basic_info_quotations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[6,"data_on_basic_info"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/:akaire_file_id/delete_akaire_file(.:format)
 * @param {any} akaire_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_akaire_file_akaire_file_index_path = __jsr.r({"akaire_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"delete_akaire_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/check_list_work/delete_all_checklist(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_all_checklist_owner_project_check_list_work_index_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"check_list_work"],[2,[7,"/"],[2,[6,"delete_all_checklist"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/delete_all_quantity_rejected(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_all_quantity_rejected_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"delete_all_quantity_rejected"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/delete_avatar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_avatar_owner_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_avatar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/check_list_work/delete_check_list(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_check_list_owner_project_check_list_work_index_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"check_list_work"],[2,[7,"/"],[2,[6,"delete_check_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/clients/:id/delete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_client_admins_companies_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id/delete_payment_revision(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_payment_revision_owner_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_payment_revision"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/:id/person_in_charges/:user_id(.:format)
 * @param {any} id
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_person_in_charge_owner_owner_path = __jsr.r({"id":{"r":true},"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"person_in_charges"],[2,[7,"/"],[2,[3,"user_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:id/manager/:user_id(.:format)
 * @param {any} id
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_person_in_charges_owner_client_path = __jsr.r({"id":{"r":true},"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"manager"],[2,[7,"/"],[2,[3,"user_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id/person_in_charges/:user_id(.:format)
 * @param {any} id
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_person_in_charges_owner_supplier_path = __jsr.r({"id":{"r":true},"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"person_in_charges"],[2,[7,"/"],[2,[3,"user_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/delete_person_incharge(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_person_incharge_owner_project_tasks_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[6,"delete_person_incharge"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /collaborators/:id/delete_pic(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_pic_owner_collaborator_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_pic"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/supplier/:id/delete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_supplier_admins_companies_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"supplier"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /efforts/deleted_works(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deleted_works_efforts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"efforts"],[2,[7,"/"],[2,[6,"deleted_works"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /demo/auth/forgot_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_auth_forgot_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"forgot_password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/auth/signin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_auth_signin_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"signin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/auth/signup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_auth_signup_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"signup"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/charts/area(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_charts_area_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[6,"area"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/charts/bar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_charts_bar_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[6,"bar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/charts/column(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_charts_column_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[6,"column"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/charts/line(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_charts_line_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[6,"line"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/charts/mixed(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_charts_mixed_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[6,"mixed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/charts/pie(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_charts_pie_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[6,"pie"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/dashboard/analytics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_dashboard_analytics_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"analytics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/dashboard/crm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_dashboard_crm_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"crm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/dashboard/ecommerce(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_dashboard_ecommerce_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"ecommerce"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/dashboard/project(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_dashboard_project_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"project"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/dashboard/sales(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_dashboard_sales_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"sales"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/dashboard/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_dashboard_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/discussions/chat(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_discussions_chat_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"discussions"],[2,[7,"/"],[2,[6,"chat"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/discussions/inbox(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_discussions_inbox_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"discussions"],[2,[7,"/"],[2,[6,"inbox"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/ui_components/feather_icons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_feather_icons_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"ui_components"],[2,[7,"/"],[2,[6,"feather_icons"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/ui_components/icofont_icons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_icofont_icons_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"ui_components"],[2,[7,"/"],[2,[6,"icofont_icons"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/ui_components/line_icons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_line_icons_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"ui_components"],[2,[7,"/"],[2,[6,"line_icons"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/pages/error(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_pages_error_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"error"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/pages/faq(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_pages_faq_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"faq"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/pages/gallery(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_pages_gallery_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"gallery"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/pages/invoice_template(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_pages_invoice_template_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"invoice_template"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/pages/notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_pages_notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/pages/pricing(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_pages_pricing_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"pricing"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/pages/profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_pages_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"profile"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/pages/profile_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_pages_profile_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"profile_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/pages/timeline(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_pages_timeline_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"timeline"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/pages/users_card(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_pages_users_card_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"users_card"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /demo/todos/lists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_todos_lists_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"todos"],[2,[7,"/"],[2,[6,"lists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/todos/notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_todos_notes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"todos"],[2,[7,"/"],[2,[6,"notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/ui_components/ui_alerts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_ui_alerts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"ui_components"],[2,[7,"/"],[2,[6,"ui_alerts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/ui_components/ui_badges(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_ui_badges_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"ui_components"],[2,[7,"/"],[2,[6,"ui_badges"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/ui_components/ui_buttons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_ui_buttons_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"ui_components"],[2,[7,"/"],[2,[6,"ui_buttons"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/ui_components/ui_cards(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_ui_cards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"ui_components"],[2,[7,"/"],[2,[6,"ui_cards"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/ui_components/ui_dropdowns(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_ui_dropdowns_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"ui_components"],[2,[7,"/"],[2,[6,"ui_dropdowns"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/ui_components/ui_forms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_ui_forms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"ui_components"],[2,[7,"/"],[2,[6,"ui_forms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/ui_components/ui_list_groups(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_ui_list_groups_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"ui_components"],[2,[7,"/"],[2,[6,"ui_list_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/ui_components/ui_modals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_ui_modals_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"ui_components"],[2,[7,"/"],[2,[6,"ui_modals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/ui_components/ui_progress_bars(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_ui_progress_bars_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"ui_components"],[2,[7,"/"],[2,[6,"ui_progress_bars"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/ui_components/ui_tables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_ui_tables_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"ui_components"],[2,[7,"/"],[2,[6,"ui_tables"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /demo/ui_components/ui_tabs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_ui_tabs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"demo"],[2,[7,"/"],[2,[6,"ui_components"],[2,[7,"/"],[2,[6,"ui_tabs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_admin_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /unread/destroy_all_unread_of_chat(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_all_unread_of_chat_owner_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[2,[7,"/"],[2,[6,"destroy_all_unread_of_chat"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /unread/destroy_all_unread_of_chat(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_all_unread_of_chat_supplier_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[2,[7,"/"],[2,[6,"destroy_all_unread_of_chat"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /unread/destroy_bookmark(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_bookmark_owner_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[2,[7,"/"],[2,[6,"destroy_bookmark"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /unread/destroy_bookmark(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_bookmark_supplier_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[2,[7,"/"],[2,[6,"destroy_bookmark"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id/contract/:contract_id/destroy(.:format)
 * @param {any} id
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_contract_owner_supplier_path = __jsr.r({"id":{"r":true},"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contract"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /orders/:id/destroy_order_received(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_order_received_owner_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy_order_received"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/destroy_request_permission(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_request_permission_owner_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy_request_permission"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id/destroy_revision(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_revision_owner_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy_revision"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /unread/destroy_unread(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_unread_owner_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[2,[7,"/"],[2,[6,"destroy_unread"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /unread/destroy_unread(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_unread_supplier_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[2,[7,"/"],[2,[6,"destroy_unread"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /efforts/destroy_work(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_work_efforts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"efforts"],[2,[7,"/"],[2,[6,"destroy_work"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/clients/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const detail_client_admins_companies_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/:id/detail(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const detail_owner_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"detail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/detail(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const detail_owner_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"detail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/supplier/:id/detail(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const detail_supplier_admins_companies_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"supplier"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"detail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/:id/detail(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const detail_supplier_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"detail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/detail(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const detail_supplier_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"detail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/disable_noti_compare_page(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disable_noti_compare_page_owner_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"disable_noti_compare_page"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/disable_noti_compare_version(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disable_noti_compare_version_owner_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"disable_noti_compare_version"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /company_ip_limits/disallowed_ip_addresses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disallowed_ip_addresses_owner_company_ip_limits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company_ip_limits"],[2,[7,"/"],[2,[6,"disallowed_ip_addresses"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /efforts/do_not_release(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const do_not_release_efforts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"efforts"],[2,[7,"/"],[2,[6,"do_not_release"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /documents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const document_management_owner_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orders/document_management(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const document_management_supplier_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"document_management"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/estimate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const document_owner_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"estimate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/document(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const document_supplier_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"document"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/downloads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_all_admins_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"downloads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/campaigns/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_campaign_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/news/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_notice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/privacy_and_terms/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_privacy_and_term_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"privacy_and_terms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaires/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_akaire_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaires"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/:id/edit(.:format)
 * @param {any} akaire_file_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_akaire_file_animation_path = __jsr.r({"akaire_file_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/:animation_id/animation_comments/:id/edit(.:format)
 * @param {any} akaire_file_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_akaire_file_animation_animation_comment_path = __jsr.r({"akaire_file_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/:animation_id/animation_markers/:id/edit(.:format)
 * @param {any} akaire_file_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_akaire_file_animation_animation_marker_path = __jsr.r({"akaire_file_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/:animation_id/extension_files/edit(.:format)
 * @param {any} akaire_file_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_akaire_file_animation_extension_files_path = __jsr.r({"akaire_file_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/:animation_id/finalization/edit(.:format)
 * @param {any} akaire_file_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_akaire_file_animation_finalization_path = __jsr.r({"akaire_file_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/pages/:id/edit(.:format)
 * @param {any} akaire_file_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_akaire_file_page_path = __jsr.r({"akaire_file_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/pages/:page_id/animations/:id/edit(.:format)
 * @param {any} akaire_file_id
 * @param {any} page_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_akaire_file_page_animation_path = __jsr.r({"akaire_file_id":{"r":true},"page_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/:id/edit(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_animation_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/:animation_id/animation_comments/:id/edit(.:format)
 * @param {any} project_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_animation_animation_comment_path = __jsr.r({"project_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/:animation_id/animation_markers/:id/edit(.:format)
 * @param {any} project_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_animation_animation_marker_path = __jsr.r({"project_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/:animation_id/extension_files/edit(.:format)
 * @param {any} project_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_animation_extension_files_path = __jsr.r({"project_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/:animation_id/finalization/edit(.:format)
 * @param {any} project_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_animation_finalization_path = __jsr.r({"project_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/pages/:id/edit(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_page_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/pages/:page_id/animations/:id/edit(.:format)
 * @param {any} project_id
 * @param {any} page_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_page_animation_path = __jsr.r({"project_id":{"r":true},"page_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/:id/edit(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_task_animation_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/:animation_id/animation_comments/:id/edit(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_task_animation_animation_comment_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/:animation_id/animation_markers/:id/edit(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_task_animation_animation_marker_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/:animation_id/extension_files/edit(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_task_animation_extension_files_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/:animation_id/finalization/edit(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_task_animation_finalization_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/pages/:id/edit(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_task_page_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/pages/:page_id/animations/:id/edit(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} page_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_task_page_animation_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"page_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:id/edit(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/:id/edit(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_task_ticket_animation_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/:animation_id/animation_comments/:id/edit(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_task_ticket_animation_animation_comment_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/:animation_id/animation_markers/:id/edit(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_task_ticket_animation_animation_marker_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/:animation_id/extension_files/edit(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_task_ticket_animation_extension_files_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/:animation_id/finalization/edit(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_task_ticket_animation_finalization_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/pages/:id/edit(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_task_ticket_page_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/pages/:page_id/animations/:id/edit(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} page_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_task_ticket_page_animation_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"page_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/user/:id/edit(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_task_ticket_user_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/user/:id/edit(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_task_user_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/user/:id/edit(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_akaire_feature_project_user_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /efforts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_effort_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"efforts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/:id/edit(.:format)
 * @param {any} object_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_object_animation_path = __jsr.r({"object_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/:animation_id/animation_comments/:id/edit(.:format)
 * @param {any} object_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_object_animation_animation_comment_path = __jsr.r({"object_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/:animation_id/animation_markers/:id/edit(.:format)
 * @param {any} object_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_object_animation_animation_marker_path = __jsr.r({"object_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/:animation_id/extension_files/edit(.:format)
 * @param {any} object_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_object_animation_extension_files_path = __jsr.r({"object_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/:animation_id/finalization/edit(.:format)
 * @param {any} object_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_object_animation_finalization_path = __jsr.r({"object_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/assignments/:id/edit(.:format)
 * @param {any} object_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_object_assignment_path = __jsr.r({"object_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"assignments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/pages/:id/edit(.:format)
 * @param {any} object_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_object_page_path = __jsr.r({"object_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/pages/:page_id/animations/:id/edit(.:format)
 * @param {any} object_id
 * @param {any} page_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_object_page_animation_path = __jsr.r({"object_id":{"r":true},"page_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:id/edit(.:format)
 * @param {any} object_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_object_ticket_path = __jsr.r({"object_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/:id/edit(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_object_ticket_animation_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/:animation_id/animation_comments/:id/edit(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_object_ticket_animation_animation_comment_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/:animation_id/animation_markers/:id/edit(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_object_ticket_animation_animation_marker_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/:animation_id/extension_files/edit(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_object_ticket_animation_extension_files_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/:animation_id/finalization/edit(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_object_ticket_animation_finalization_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/pages/:id/edit(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_object_ticket_page_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/pages/:page_id/animations/:id/edit(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} page_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_object_ticket_page_animation_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"page_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/user/:id/edit(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_object_ticket_user_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /add_all_child/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_owner_add_all_child_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"add_all_child"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /campaigns/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_owner_campaign_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_owner_client_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collaborators/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_owner_collaborator_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_owner_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /company_info_bank_transfers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_owner_company_info_bank_transfer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"company_info_bank_transfers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /company_ip_limits/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_owner_company_ip_limit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"company_ip_limits"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /contract_templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_owner_contract_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contract_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /invoice_payments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_owner_invoice_payment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoice_payments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member_stores/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_owner_member_store_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member_stores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /orders/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_owner_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /owners/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_owner_owner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /option/contract/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_owner_pages_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"option"],[2,[7,"/"],[2,[6,"contract"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /option/plan/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_owner_plans_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"option"],[2,[7,"/"],[2,[6,"plan"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /policies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_owner_policy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"policies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/info(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_owner_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/edit(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_owner_project_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/edit(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_owner_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:supplier_id/portfolios/:id/edit(.:format)
 * @param {any} supplier_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_owner_supplier_portfolio_path = __jsr.r({"supplier_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"supplier_id"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_owner_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /orders/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_supplier_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pages/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_supplier_page_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /plans/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_supplier_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_supplier_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/edit(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_supplier_project_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/edit(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_supplier_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /supplier_registers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_supplier_register_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"supplier_registers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_supplier_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /efforts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const effort_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"efforts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /efforts/:effort_id/histories(.:format)
 * @param {any} effort_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const effort_histories_path = __jsr.r({"effort_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"efforts"],[2,[7,"/"],[2,[3,"effort_id"],[2,[7,"/"],[2,[6,"histories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /efforts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const efforts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"efforts"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /invoices/:id/emancipate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const emancipate_owner_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"emancipate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /subscriptions/error_payment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const error_payment_owner_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"error_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/:object_id/export_akaire_files(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_akaire_files_akaire_file_index_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"export_akaire_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /export_task/:project_id(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_tasks_get_path = __jsr.r({"project_id":{"r":true},"format":{"d":"csv"}}, [2,[7,"/"],[2,[6,"export_task"],[2,[7,"/"],[2,[3,"project_id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /export_task/:project_id(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_tasks_post_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"export_task"],[2,[7,"/"],[2,[3,"project_id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /export_ticket/:task_id(.:format)
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_tickets_get_path = __jsr.r({"task_id":{"r":true},"format":{"d":"csv"}}, [2,[7,"/"],[2,[6,"export_ticket"],[2,[7,"/"],[2,[3,"task_id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /export_ticket/:task_id(.:format)
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_tickets_post_path = __jsr.r({"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"export_ticket"],[2,[7,"/"],[2,[3,"task_id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/fetch_account_akaire_page(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fetch_account_akaire_page_owner_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"fetch_account_akaire_page"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/fetch_dateline_of_task(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fetch_dateline_of_task_owner_project_tasks_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[6,"fetch_dateline_of_task"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /discussions/fetch_discussion(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fetch_discussion_owner_discussions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"discussions"],[2,[7,"/"],[2,[6,"fetch_discussion"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/fetch_list_project_of_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fetch_list_project_of_user_akaire_file_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[6,"fetch_list_project_of_user"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/fetch_list_task_of_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fetch_list_task_of_user_akaire_file_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[6,"fetch_list_task_of_user"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/fetch_list_ticket_of_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fetch_list_ticket_of_user_akaire_file_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[6,"fetch_list_ticket_of_user"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/fetch_max_quantity(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fetch_max_quantity_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"fetch_max_quantity"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/fetch_order_with_ticket(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fetch_order_with_ticket_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"fetch_order_with_ticket"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/fetch_total_effort_of_company(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fetch_total_effort_of_company_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"fetch_total_effort_of_company"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/file_mp4_exists(.:format)
 * @param {any} akaire_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const file_mp4_exists_akaire_feature_akaire_file_animations_path = __jsr.r({"akaire_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"file_mp4_exists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/file_mp4_exists(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const file_mp4_exists_akaire_feature_project_animations_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"file_mp4_exists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/file_mp4_exists(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const file_mp4_exists_akaire_feature_project_task_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"file_mp4_exists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/file_mp4_exists(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const file_mp4_exists_akaire_feature_project_task_ticket_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"file_mp4_exists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/file_mp4_exists(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const file_mp4_exists_object_animations_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"file_mp4_exists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/file_mp4_exists(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const file_mp4_exists_object_ticket_animations_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"file_mp4_exists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/:animation_id/finalization/finalize_animation(.:format)
 * @param {any} akaire_file_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finalize_animation_akaire_feature_akaire_file_animation_finalization_path = __jsr.r({"akaire_file_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[2,[7,"/"],[2,[6,"finalize_animation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/:animation_id/finalization/finalize_animation(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finalize_animation_akaire_feature_project_task_animation_finalization_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[2,[7,"/"],[2,[6,"finalize_animation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/:animation_id/finalization/finalize_animation(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finalize_animation_akaire_feature_project_task_ticket_animation_finalization_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[2,[7,"/"],[2,[6,"finalize_animation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/:animation_id/finalization/finalize_animation(.:format)
 * @param {any} object_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finalize_animation_object_animation_finalization_path = __jsr.r({"object_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[2,[7,"/"],[2,[6,"finalize_animation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/:animation_id/finalization/finalize_animation(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finalize_animation_object_ticket_animation_finalization_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[2,[7,"/"],[2,[6,"finalize_animation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/:id/get_akaire_file_history(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_akaire_file_history_akaire_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_akaire_file_history"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/clients/:keycode/info(.:format)
 * @param {any} keycode
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_client_info_admins_companies_path = __jsr.r({"keycode":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"keycode"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /efforts/get_client_name(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_client_name_efforts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"efforts"],[2,[7,"/"],[2,[6,"get_client_name"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/invite/:supplier_id(.:format)
 * @param {any} supplier_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_invite_owner_suppliers_path = __jsr.r({"supplier_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[6,"invite"],[2,[7,"/"],[2,[3,"supplier_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/get_list_akaire_file_of_project(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_list_akaire_file_of_project_owner_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_list_akaire_file_of_project"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member_stores/get_list_campaign(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_list_campaign_owner_member_stores_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member_stores"],[2,[7,"/"],[2,[6,"get_list_campaign"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/get_list_task_of_project(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_list_task_of_project_owner_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_list_task_of_project"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/news/get_maintenance(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_maintenance_admins_notices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[6,"get_maintenance"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/get_tickets(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_tickets_owner_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /unread/group(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const group_owner_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[2,[7,"/"],[2,[6,"group"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /unread/group(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const group_supplier_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[2,[7,"/"],[2,[6,"group"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /stripe_webhooks/handle_card(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const handle_card_owner_stripe_webhooks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"stripe_webhooks"],[2,[7,"/"],[2,[6,"handle_card"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /efforts/handle_change_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const handle_change_status_efforts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"efforts"],[2,[7,"/"],[2,[6,"handle_change_status"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /subscriptions/handle_check_change_plan(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const handle_check_change_plan_owner_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"handle_check_change_plan"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/hide_popup_introduce_id_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const hide_popup_introduce_id_password_owner_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"hide_popup_introduce_id_password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/hide_popup_notify_auto_setting_effort(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const hide_popup_notify_auto_setting_effort_owner_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"hide_popup_notify_auto_setting_effort"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/hide_popup_warning_ticket_akapon(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const hide_popup_warning_ticket_akapon_owner_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"hide_popup_warning_ticket_akapon"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /option/contract/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const index_owner_pages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"option"],[2,[7,"/"],[2,[6,"contract"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /option/plan/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const info_owner_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"option"],[2,[7,"/"],[2,[6,"plan"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /campaigns/invite(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invite_owner_campaigns_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[6,"invite"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member_stores/invite(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invite_owner_member_stores_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member_stores"],[2,[7,"/"],[2,[6,"invite"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member_stores/:id/invoice_details(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoice_details_owner_member_store_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member_stores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"invoice_details"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/:type/list(.:format)
 * @param {any} type
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const list_admins_companies_path = __jsr.r({"type":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"type"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/:id/list_akaire_page_of_file(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const list_akaire_page_of_file_akaire_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"list_akaire_page_of_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/:id/list_orders(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const list_orders_owner_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"list_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/:id/list_orders(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const list_orders_supplier_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"list_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/list_request_quotation(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const list_request_quotation_owner_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"list_request_quotation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:id/list_tasks(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const list_tasks_owner_quotation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"list_tasks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaires/list_temp_file(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const list_temp_file_akaire_feature_akaires_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaires"],[2,[7,"/"],[2,[6,"list_temp_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /messages/load_add_messages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const load_add_messages_messages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"load_add_messages"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id/mail_payment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mail_payment_owner_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"mail_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /campaigns/mail_template(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mail_template_owner_campaigns_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[6,"mail_template"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member_stores/mail_template(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mail_template_owner_member_stores_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member_stores"],[2,[7,"/"],[2,[6,"mail_template"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /messages/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const message_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /messages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const messages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"messages"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/check_list_work/messages_of_check_list(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const messages_of_check_list_owner_project_check_list_work_index_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"check_list_work"],[2,[7,"/"],[2,[6,"messages_of_check_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/modal_change_status(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const modal_change_status_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"modal_change_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/campaigns/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_campaign_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/news/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_notice_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/privacy_and_terms/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_privacy_and_term_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"privacy_and_terms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaires/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_akaire_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaires"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/new(.:format)
 * @param {any} akaire_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_akaire_file_animation_path = __jsr.r({"akaire_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/:animation_id/animation_comments/new(.:format)
 * @param {any} akaire_file_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_akaire_file_animation_animation_comment_path = __jsr.r({"akaire_file_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/:animation_id/animation_markers/new(.:format)
 * @param {any} akaire_file_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_akaire_file_animation_animation_marker_path = __jsr.r({"akaire_file_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/:animation_id/extension_files/new(.:format)
 * @param {any} akaire_file_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_akaire_file_animation_extension_files_path = __jsr.r({"akaire_file_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/:animation_id/finalization/new(.:format)
 * @param {any} akaire_file_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_akaire_file_animation_finalization_path = __jsr.r({"akaire_file_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/pages/new(.:format)
 * @param {any} akaire_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_akaire_file_page_path = __jsr.r({"akaire_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/pages/:page_id/animations/new(.:format)
 * @param {any} akaire_file_id
 * @param {any} page_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_akaire_file_page_animation_path = __jsr.r({"akaire_file_id":{"r":true},"page_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/new(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_animation_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/:animation_id/animation_comments/new(.:format)
 * @param {any} project_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_animation_animation_comment_path = __jsr.r({"project_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/:animation_id/animation_markers/new(.:format)
 * @param {any} project_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_animation_animation_marker_path = __jsr.r({"project_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/:animation_id/extension_files/new(.:format)
 * @param {any} project_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_animation_extension_files_path = __jsr.r({"project_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/:animation_id/finalization/new(.:format)
 * @param {any} project_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_animation_finalization_path = __jsr.r({"project_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/pages/new(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_page_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/pages/:page_id/animations/new(.:format)
 * @param {any} project_id
 * @param {any} page_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_page_animation_path = __jsr.r({"project_id":{"r":true},"page_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/new(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_task_animation_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/:animation_id/animation_comments/new(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_task_animation_animation_comment_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/:animation_id/animation_markers/new(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_task_animation_animation_marker_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/:animation_id/extension_files/new(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_task_animation_extension_files_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/:animation_id/finalization/new(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_task_animation_finalization_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/pages/new(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_task_page_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/pages/:page_id/animations/new(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} page_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_task_page_animation_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"page_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/new(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/new(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_task_ticket_animation_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/:animation_id/animation_comments/new(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_task_ticket_animation_animation_comment_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/:animation_id/animation_markers/new(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_task_ticket_animation_animation_marker_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/:animation_id/extension_files/new(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_task_ticket_animation_extension_files_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/:animation_id/finalization/new(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_task_ticket_animation_finalization_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/pages/new(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_task_ticket_page_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/pages/:page_id/animations/new(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {any} page_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_task_ticket_page_animation_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"page_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/user/new(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_task_ticket_user_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/user/new(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_task_user_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/user/new(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_akaire_feature_project_user_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id/contract/new(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_contract_owner_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contract"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id/contracts/:contract_id/new_revision(.:format)
 * @param {any} id
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_contract_revision_owner_supplier_path = __jsr.r({"id":{"r":true},"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"new_revision"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /efforts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_effort_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"efforts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/new(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_object_animation_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/:animation_id/animation_comments/new(.:format)
 * @param {any} object_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_object_animation_animation_comment_path = __jsr.r({"object_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/:animation_id/animation_markers/new(.:format)
 * @param {any} object_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_object_animation_animation_marker_path = __jsr.r({"object_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/:animation_id/extension_files/new(.:format)
 * @param {any} object_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_object_animation_extension_files_path = __jsr.r({"object_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/:animation_id/finalization/new(.:format)
 * @param {any} object_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_object_animation_finalization_path = __jsr.r({"object_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/assignments/new(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_object_assignment_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"assignments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/pages/new(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_object_page_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/pages/:page_id/animations/new(.:format)
 * @param {any} object_id
 * @param {any} page_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_object_page_animation_path = __jsr.r({"object_id":{"r":true},"page_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/new(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_object_ticket_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/new(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_object_ticket_animation_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/:animation_id/animation_comments/new(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_object_ticket_animation_animation_comment_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/:animation_id/animation_markers/new(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_object_ticket_animation_animation_marker_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/:animation_id/extension_files/new(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_object_ticket_animation_extension_files_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/:animation_id/finalization/new(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_object_ticket_animation_finalization_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/pages/new(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_object_ticket_page_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/pages/:page_id/animations/new(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} page_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_object_ticket_page_animation_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"page_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/user/new(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_object_ticket_user_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /add_all_child/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_owner_add_all_child_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"add_all_child"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /campaigns/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_owner_campaign_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /clients/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_owner_client_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /collaborators/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_owner_collaborator_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /companies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_owner_company_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /company_info_bank_transfers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_owner_company_info_bank_transfer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company_info_bank_transfers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /company_ip_limits/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_owner_company_ip_limit_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company_ip_limits"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /contract_templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_owner_contract_template_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contract_templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member_stores/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_owner_member_store_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member_stores"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /owners/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_owner_owner_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /option/contract/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_owner_pages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"option"],[2,[7,"/"],[2,[6,"contract"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /password_resets/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_owner_password_reset_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"password_resets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /option/plan/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_owner_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"option"],[2,[7,"/"],[2,[6,"plan"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /policies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_owner_policy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"policies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_owner_project_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/new(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/submission/sale/quotation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_owner_quotation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"submission"],[2,[7,"/"],[2,[6,"sale"],[2,[7,"/"],[2,[6,"quotation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/request_for_quotations/new(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_owner_request_for_quotation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"request_for_quotations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:supplier_id/portfolios/new(.:format)
 * @param {any} supplier_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_owner_supplier_portfolio_path = __jsr.r({"supplier_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"supplier_id"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_owner_suppliers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_owner_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pages/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_supplier_page_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /password_resets/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_supplier_password_reset_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"password_resets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /plans/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_supplier_plan_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/new(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_supplier_project_task_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/new(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_supplier_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/quotations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_supplier_quotation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /supplier_registers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_supplier_register_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"supplier_registers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_supplier_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /news(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const news_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"news"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /p/objects/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/:id(.:format)
 * @param {any} object_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_animation_path = __jsr.r({"object_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/:animation_id/animation_comments/:id(.:format)
 * @param {any} object_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_animation_animation_comment_path = __jsr.r({"object_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/:animation_id/animation_comments(.:format)
 * @param {any} object_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_animation_animation_comments_path = __jsr.r({"object_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/:animation_id/animation_markers/:id(.:format)
 * @param {any} object_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_animation_animation_marker_path = __jsr.r({"object_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/:animation_id/animation_markers(.:format)
 * @param {any} object_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_animation_animation_markers_path = __jsr.r({"object_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/:animation_id/extension_files(.:format)
 * @param {any} object_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_animation_extension_files_path = __jsr.r({"object_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/:animation_id/finalization(.:format)
 * @param {any} object_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_animation_finalization_path = __jsr.r({"object_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_animations_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/assignments/:id(.:format)
 * @param {any} object_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_assignment_path = __jsr.r({"object_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"assignments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/assignments(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_assignments_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"assignments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/pages/:id(.:format)
 * @param {any} object_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_page_path = __jsr.r({"object_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/pages/:page_id/animations/:id(.:format)
 * @param {any} object_id
 * @param {any} page_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_page_animation_path = __jsr.r({"object_id":{"r":true},"page_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/pages/:page_id/animations(.:format)
 * @param {any} object_id
 * @param {any} page_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_page_animations_path = __jsr.r({"object_id":{"r":true},"page_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/pages(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_pages_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"pages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /object_sorts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_sort_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"object_sorts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /object_sorts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_sorts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"object_sorts"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:id(.:format)
 * @param {any} object_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_ticket_path = __jsr.r({"object_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/:id(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_ticket_animation_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/:animation_id/animation_comments/:id(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_ticket_animation_animation_comment_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/:animation_id/animation_comments(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_ticket_animation_animation_comments_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_comments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/:animation_id/animation_markers/:id(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_ticket_animation_animation_marker_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/:animation_id/animation_markers(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_ticket_animation_animation_markers_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/:animation_id/extension_files(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_ticket_animation_extension_files_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"extension_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/:animation_id/finalization(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_ticket_animation_finalization_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"finalization"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_ticket_animations_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/pages/:id(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_ticket_page_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/pages/:page_id/animations/:id(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} page_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_ticket_page_animation_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"page_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/pages/:page_id/animations(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} page_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_ticket_page_animations_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"page_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"animations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/pages(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_ticket_pages_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"pages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/user/:id(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_ticket_user_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/user(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_ticket_user_index_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_tickets_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/user(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const object_user_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/receipt/sale/purchasing/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_received_owner_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"receipt"],[2,[7,"/"],[2,[6,"sale"],[2,[7,"/"],[2,[6,"purchasing"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /orders/orders_received(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_received_supplier_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"orders_received"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /documents/submission/purchasing/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_sended_owner_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"submission"],[2,[7,"/"],[2,[6,"purchasing"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/quotation/:id(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"quotation"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /notification(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_activities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"notification"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /notification/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_activity_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"notification"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /add_all_child/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_add_all_child_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"add_all_child"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /add_all_child(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_add_all_child_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"add_all_child"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/invite(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_add_user_invite_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"invite"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/authority(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_add_user_permirssion_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"authority"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /complete_company_registration/:member_plan_id/:register_token(.:format)
 * @param {any} member_plan_id
 * @param {any} register_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_akapon_complete_company_registration_path = __jsr.r({"member_plan_id":{"r":true},"register_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"complete_company_registration"],[2,[7,"/"],[2,[3,"member_plan_id"],[2,[7,"/"],[2,[3,"register_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akapon_first_login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_akapon_first_login_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akapon_first_login"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /akapon_resend(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_akapon_resend_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akapon_resend"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /resend_confirm_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_akapon_resend_confirm_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"resend_confirm_email"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /akapon_signup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_akapon_signup_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akapon_signup"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /akapon_signup/step2/:register_token(.:format)
 * @param {any} register_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_akapon_signup_step2_path = __jsr.r({"register_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akapon_signup"],[2,[7,"/"],[2,[6,"step2"],[2,[7,"/"],[2,[3,"register_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /success(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_akapon_success_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"success"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /akapon_signup/thanks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_akapon_thanks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akapon_signup"],[2,[7,"/"],[2,[6,"thanks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /notification/all_read(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_all_read_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"notification"],[2,[7,"/"],[2,[6,"all_read"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /project/:project_id/task/:task_id/order/:id/approval(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_approval_task_order_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"project"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"order"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approval"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/approve_invitation/:invite_token(.:format)
 * @param {any} invite_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_approve_invitation_path = __jsr.r({"invite_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[6,"approve_invitation"],[2,[7,"/"],[2,[3,"invite_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/basic_info(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_basic_info_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"basic_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /campaigns/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_campaign_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /campaigns(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_campaigns_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"campaigns"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /quotations/order/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_cancel_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[6,"order"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /cards/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_card_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cards(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_cards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cards"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /clients/:id/change_client_name(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_change_client_name_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"change_client_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:id/change_client_to_partner(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_change_client_to_partner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"change_client_to_partner"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:id/change_client_to_supplier(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_change_client_to_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"change_client_to_supplier"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/choose_color(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_change_color_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"choose_color"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/change_show_time_client(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_change_show_time_client_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"change_show_time_client"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /subscriptions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_change_subscriptions_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /supplier/:id/change_supplier_to_client(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_change_supplier_to_client_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"supplier"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"change_supplier_to_client"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/update_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_changer_project_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:company_id/chat(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_chat_supplier_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"chat"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/chat(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_chat_tasks_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"chat"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tickets/:id/chat(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_chat_tickets_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"chat"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:user_id/chat(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_chat_user_path = __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"chat"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:company_id/chat(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_chat_with_client_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"chat"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collaborators/:company_id/chat(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_chat_with_collab_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"chat"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/:company_id/chat(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_chat_with_owner_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"chat"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/task/:id/check_partner_by_email(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_check_partner_by_email_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_partner_by_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/task/:id/check_pic_by_email(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_check_pic_to_task_by_email_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_pic_by_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/check_quotation/supplier/:supplier_id(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} supplier_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_check_quotation_contract_type_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"supplier_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"check_quotation"],[2,[7,"/"],[2,[6,"supplier"],[2,[7,"/"],[2,[3,"supplier_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /check_read_term(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_check_read_term_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"check_read_term"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /check_signup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_check_signup_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"check_signup"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/choose_color(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_choose_color_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"choose_color"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /owner/client_approval(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_approval_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"owner"],[2,[7,"/"],[2,[6,"client_approval"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /clients/approve_invitation/:invite_token(.:format)
 * @param {any} invite_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_approve_invitation_path = __jsr.r({"invite_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[6,"approve_invitation"],[2,[7,"/"],[2,[3,"invite_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:id/be_invited(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_be_invited_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"be_invited"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:id/contract/info(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contract"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /owner/:id/destroy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_delete_payment_revision_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owner"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /owner/contract/:id/destroy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_destroy_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owner"],[2,[7,"/"],[2,[6,"contract"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_download_csv_path = __jsr.r({"format":{"d":"csv"}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /clients/approve_invitation/:invite_token(.:format)
 * @param {any} invite_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_invitation_path = __jsr.r({"invite_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[6,"approve_invitation"],[2,[7,"/"],[2,[3,"invite_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:id/contract/new(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_new_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contract"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:id/contracts/:contract_id/new_revision(.:format)
 * @param {any} id
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_new_contract_revision_path = __jsr.r({"id":{"r":true},"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"new_revision"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/contract_revision/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_owner_destroy_revision_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[6,"contract_revision"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /client/new_contract(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_owner_new_contract_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"client"],[2,[7,"/"],[2,[6,"new_contract"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /owners/:id/payment_edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_owner_show_payment_method_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"payment_edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:id/contracts/new_contract(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_post_new_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"new_contract"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:id/contracts/:contract_id/new_revision(.:format)
 * @param {any} id
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_post_new_contract_revision_path = __jsr.r({"id":{"r":true},"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"new_revision"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/quick_approve_invitation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_quick_approve_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[6,"quick_approve_invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /clients/re_invite(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_re_invite_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[6,"re_invite"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /clients/reject_invitation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_reject_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[6,"reject_invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /clients/remove_invitation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_remove_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[6,"remove_invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /clients/contract_revision/:id/note(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_show_note_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[6,"contract_revision"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:id/payment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_show_payment_method_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/contract_revision/:id/content(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_show_pdf_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[6,"contract_revision"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"content"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:id/contracts/:contract_id/sign_contract(.:format)
 * @param {any} id
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_sign_contract_path = __jsr.r({"id":{"r":true},"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"sign_contract"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /client_signup/step2/:register_token(.:format)
 * @param {any} register_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_signup_new_path = __jsr.r({"register_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"client_signup"],[2,[7,"/"],[2,[6,"step2"],[2,[7,"/"],[2,[3,"register_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:id/payment_edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_update_payment_method_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"payment_edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /client_privacy_and_policy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_view_policy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"client_privacy_and_policy"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /client_term_conditions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_view_term_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"client_term_conditions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /clients/:id/waiting_approve(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_client_waiting_comfirm_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"waiting_approve"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_clients_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"clients"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /collaborators/approve_invitation/:invite_token(.:format)
 * @param {any} invite_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_collab_approve_invitation_path = __jsr.r({"invite_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[6,"approve_invitation"],[2,[7,"/"],[2,[3,"invite_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collaborators/approve_invitation/:invite_token(.:format)
 * @param {any} invite_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_collab_invitation_path = __jsr.r({"invite_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[6,"approve_invitation"],[2,[7,"/"],[2,[3,"invite_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collaborators/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_collaborator_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /collaborator_signup/step2/:register_token(.:format)
 * @param {any} register_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_collaborator_signup_new_path = __jsr.r({"register_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collaborator_signup"],[2,[7,"/"],[2,[6,"step2"],[2,[7,"/"],[2,[3,"register_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collaborators(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_collaborators_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"collaborators"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /company/bank(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_company_bank_accounts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"bank"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /company_info_bank_transfers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_company_info_bank_transfer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"company_info_bank_transfers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /company_info_bank_transfers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_company_info_bank_transfers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company_info_bank_transfers"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /company_ip_limits/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_company_ip_limit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"company_ip_limits"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /company_ip_limits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_company_ip_limits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company_ip_limits"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /company/private_domain(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_company_private_domain_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"private_domain"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /company/remove_contract(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_company_remove_contract_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"remove_contract"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /company/save_signature(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_company_save_signature_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"save_signature"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /completed_akaire_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_completed_akaire_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"completed_akaire_files"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /completed_tasks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_completed_tasks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"completed_tasks"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /completion(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_completion_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"completion"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /contact_us(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_contact_us_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contact_us"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /owner/contract(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_contract_client_owner_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"owner"],[2,[7,"/"],[2,[6,"contract"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /contract_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_contract_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contract_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /contract_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_contract_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contract_templates"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /akapon_signup/success(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_create_akapon_step1_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akapon_signup"],[2,[7,"/"],[2,[6,"success"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /clients/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_create_client_normal_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/log_work(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_create_log_work_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"log_work"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /create_new_request_for_quotation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_create_new_request_for_quotation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"create_new_request_for_quotation"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /company/payment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_create_payment_method_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"payment"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /payment_term_revisions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_create_payment_term_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"payment_term_revisions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /register_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_create_register_user_keycode_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"register_user"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /subscriptions/:id/create(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_create_subscriptions_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_dashboard_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /clients/:id/delete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_delete_client_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collaborators/:id/delete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_delete_collaborator_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /request_for_quotations/sender/:rfq_id/delete(.:format)
 * @param {any} rfq_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_delete_request_for_quotations_sender_path = __jsr.r({"rfq_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"request_for_quotations"],[2,[7,"/"],[2,[6,"sender"],[2,[7,"/"],[2,[3,"rfq_id"],[2,[7,"/"],[2,[6,"delete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:supplier_id/delete(.:format)
 * @param {any} supplier_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_delete_supplier_path = __jsr.r({"supplier_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"supplier_id"],[2,[7,"/"],[2,[6,"delete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/destroy_log_work/:work_id(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {any} work_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_destroy_log_work_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"work_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy_log_work"],[2,[7,"/"],[2,[3,"work_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_destroy_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /project/:project_id/task/:task_id/order/:id/destroy(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_destroy_task_order_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"project"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"order"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/destroy_user_invite/:user_id(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_destroy_user_invite_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy_user_invite"],[2,[7,"/"],[2,[3,"user_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/invite/delete/:user_id(.:format)
 * @param {any} id
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_destroy_user_permission_path = __jsr.r({"id":{"r":true},"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"invite"],[2,[7,"/"],[2,[6,"delete"],[2,[7,"/"],[2,[3,"user_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/quotation/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_detail_request_quotation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[6,"quotation"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /option/quotation_consultation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_discussions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"option"],[2,[7,"/"],[2,[6,"quotation_consultation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /company/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_edit_owner_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /payment_method(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_edit_payment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"payment_method"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/:id/info(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_edit_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/request_for_quotations/filter_supplier(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_filter_supplier_request_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"request_for_quotations"],[2,[7,"/"],[2,[6,"filter_supplier"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:keycode/info(.:format)
 * @param {any} keycode
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_get_client_info_path = __jsr.r({"keycode":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"keycode"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/get_company_by_company_type(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_get_company_by_company_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_company_by_company_type"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/task/get_company(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_get_company_by_task_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[6,"get_company"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/get_company(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_get_company_in_tasks_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_company"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/get_work_histosry(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_get_get_work_histosry_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_work_histosry"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/task/:id/get_list_ticket_of_task(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_get_list_ticket_of_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_list_ticket_of_task"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /notice/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_get_notice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"notice"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /plan-detail/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_get_plan_detail_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"plan-detail"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/show_quotation(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_get_quotation_present_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"show_quotation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/get_quotation(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_get_quotations_by_current_company_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_quotation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:keycode/info(.:format)
 * @param {any} keycode
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_get_supplier_info_path = __jsr.r({"keycode":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"keycode"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/get_users_of_company(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_get_user_of_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_users_of_company"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/task/get_user(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_get_user_of_task_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[6,"get_user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/get_user_permission/:user_id(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_get_user_permission_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_user_permission"],[2,[7,"/"],[2,[3,"user_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/get_users(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_get_users_in_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/get_users_permirssions(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_get_users_permirssions_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_users_permirssions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /goodbye(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_goodbye_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"goodbye"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /grant_chart(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_grant_charts_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"grant_chart"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /suppliers/approve_invitation/:invite_token(.:format)
 * @param {any} invite_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_invitation_path = __jsr.r({"invite_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[6,"approve_invitation"],[2,[7,"/"],[2,[3,"invite_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/invite_new_permission(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_invite_new_permission_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"invite_new_permission"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/authority(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_invite_users_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"authority"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /invoice_payments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_invoice_payment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoice_payments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /invoice_payments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_invoice_payments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoice_payments"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /notification/is_read(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_is_read_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"notification"],[2,[7,"/"],[2,[6,"is_read"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/list_akaire_files_from_ticket/:work_id(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {any} work_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_list_akaire_files_from_ticket_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"work_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"list_akaire_files_from_ticket"],[2,[7,"/"],[2,[3,"work_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_list_client_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"clients"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /collaborators(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_list_collaborators_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"collaborators"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/task(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_list_grantcharts_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"task"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/submission/payment/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_list_invoice_payments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"submission"],[2,[7,"/"],[2,[6,"payment"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/submission/sale/invoice/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_list_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"submission"],[2,[7,"/"],[2,[6,"sale"],[2,[7,"/"],[2,[6,"invoice"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/list_quotations(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_list_quotations_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"list_quotations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /list_request_for_quotations/receive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_list_request_for_quotations_receive_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"list_request_for_quotations"],[2,[7,"/"],[2,[6,"receive"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /list_request_for_quotations_sender/sender(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_list_request_for_quotations_sender_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"list_request_for_quotations_sender"],[2,[7,"/"],[2,[6,"sender"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /subscription/list_subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_list_subscription_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscription"],[2,[7,"/"],[2,[6,"list_subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /subscriptionsc/:invoice_id(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_list_subscription_invoice_path = __jsr.r({"invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"subscriptionsc"],[2,[7,"/"],[2,[3,"invoice_id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/get_users(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_list_user_not_user_permission_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/:id/ticket(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_log_work_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"ticket"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /logout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_logout_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"logout"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /member_signup/step2/:register_token(.:format)
 * @param {any} register_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_member_signup_new_path = __jsr.r({"register_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member_signup"],[2,[7,"/"],[2,[6,"step2"],[2,[7,"/"],[2,[3,"register_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member_stores/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_member_store_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member_stores"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member_stores(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_member_stores_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member_stores"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /payment_method/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_new_payment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"payment_method"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /new_request_for_quotation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_new_request_for_quotation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"new_request_for_quotation"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /subscriptions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_new_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/new(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_new_tasks_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /order/get_company_payment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_order_get_company_payment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"order"],[2,[7,"/"],[2,[6,"get_company_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /owners/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /clients/:id/owner_approval(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_approval_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"owner_approval"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/contract_revisions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_client_destroy_revision_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[6,"contract_revisions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/:id/contracts(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contracts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/contract/:id/destroy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_destroy_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[6,"contract"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/:id/contracts/new_contract(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_new_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"new_contract"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/:id/contracts/:contract_id/new_revision(.:format)
 * @param {any} id
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_new_contract_revision_path = __jsr.r({"id":{"r":true},"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"new_revision"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/:id/contracts/new_contract(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_post_new_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"new_contract"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/:id/contracts/:contract_id/new_revision(.:format)
 * @param {any} id
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_post_new_contract_revision_path = __jsr.r({"id":{"r":true},"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"new_revision"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/approval(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_quotation_approval_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"approval"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_quotation_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/destroy(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_quotation_destroy_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/edit(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_quotation_edit_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/projects/:project_id/quotations/new(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_quotation_new_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/order_received(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_quotation_order_received_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"order_received"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/policy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_quotation_post_policy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[6,"policy"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/reject(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_quotation_reject_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"reject"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/released(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_quotation_released_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"released"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/show(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_quotation_show_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"show"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/submitted(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_quotation_submitted_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"submitted"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/update(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_quotation_update_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/submission/sale/quotation/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_quotations_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"submission"],[2,[7,"/"],[2,[6,"sale"],[2,[7,"/"],[2,[6,"quotation"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/receipt/purchasing/quotation/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_received_quotations_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"receipt"],[2,[7,"/"],[2,[6,"purchasing"],[2,[7,"/"],[2,[6,"quotation"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/contract_revision/:id/note(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_show_note_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[6,"contract_revision"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/contract_revision/:id/content(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_show_pdf_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[6,"contract_revision"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"content"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/:id/contracts/:contract_id/sign_contract(.:format)
 * @param {any} id
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owner_sign_contract_path = __jsr.r({"id":{"r":true},"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"sign_contract"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /owners(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_owners_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"owners"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /option/contract/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_page_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"option"],[2,[7,"/"],[2,[6,"contract"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pages/view/:slug/:id(.:format)
 * @param {any} slug
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_pages_view_unauth_path = __jsr.r({"slug":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"view"],[2,[7,"/"],[2,[3,"slug"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /password_resets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_password_resets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"password_resets"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /companies/:id/payment_term_revisions(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_payment_term_revisions_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"payment_term_revisions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /option/plan/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"option"],[2,[7,"/"],[2,[6,"plan"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /option/plan/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"option"],[2,[7,"/"],[2,[6,"plan"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /policies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_policies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"policies"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /policies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_policy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"policies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /collaborator_signup/step2/akapon/:register_token(.:format)
 * @param {any} register_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_post_akapon_create_collaborator_path = __jsr.r({"register_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collaborator_signup"],[2,[7,"/"],[2,[6,"step2"],[2,[7,"/"],[2,[6,"akapon"],[2,[7,"/"],[2,[3,"register_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /client_signup/step2/:register_token(.:format)
 * @param {any} register_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_post_client_signup_new_path = __jsr.r({"register_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"client_signup"],[2,[7,"/"],[2,[6,"step2"],[2,[7,"/"],[2,[3,"register_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collaborator_signup/step2/:register_token(.:format)
 * @param {any} register_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_post_collaborator_signup_new_path = __jsr.r({"register_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collaborator_signup"],[2,[7,"/"],[2,[6,"step2"],[2,[7,"/"],[2,[3,"register_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /create_client(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_post_create_client_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"create_client"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /company/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_post_edit_owner_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /invoice_payment/create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_post_invoice_payment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoice_payment"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member_signup/step2/:register_token(.:format)
 * @param {any} register_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_post_member_signup_new_path = __jsr.r({"register_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member_signup"],[2,[7,"/"],[2,[6,"step2"],[2,[7,"/"],[2,[3,"register_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /payment/create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_post_new_payment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"payment"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /project/:project_id/task/:task_id/post_order_received(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_post_orders_received_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"project"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"post_order_received"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /project/:project_id/task/:task_id/post_order_sended(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_post_orders_sended_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"project"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"post_order_sended"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /company/payment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_post_payment_method_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"payment"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /plans-policy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_post_policy_plan_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"plans-policy"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /resend(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_post_resend_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"resend"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /resend_mail(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_post_resend_mail_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"resend_mail"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /signin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_post_signin_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signin"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /signup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_post_signup_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signup"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /signup/step3/:member_plan_id/:register_token(.:format)
 * @param {any} member_plan_id
 * @param {any} register_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_post_signup_new_path = __jsr.r({"member_plan_id":{"r":true},"register_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signup"],[2,[7,"/"],[2,[6,"step3"],[2,[7,"/"],[2,[3,"member_plan_id"],[2,[7,"/"],[2,[3,"register_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/chat(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_chat_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"chat"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/chat(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_chat_list_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"chat"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/check_list_work(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_check_list_work_index_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"check_list_work"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/duplicate_project_deleted(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_duplicate_project_deleted_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"duplicate_project_deleted"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/orders(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_orders_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/project_deleted(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_project_deleted_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"project_deleted"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/quotation_present(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_quotation_present_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"quotation_present"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/quotation_receive(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_quotation_receive_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"quotation_receive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/render_modal_select_est_with_dup_project(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_render_modal_select_est_with_dup_project_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"render_modal_select_est_with_dup_project"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/render_modal_select_user_duplicate(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_render_modal_select_user_duplicate_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"render_modal_select_user_duplicate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/request_details(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_request_details_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"request_details"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/activity(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_show_activity_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"activity"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/all_orders_between_company(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_task_all_orders_between_company_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"all_orders_between_company"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/change_status(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_task_change_status_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"change_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/change_status_of_task_with_akaire(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_task_change_status_of_task_with_akaire_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"change_status_of_task_with_akaire"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/change_task_type(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_task_change_task_type_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"change_task_type"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/check_delete_task(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_task_check_delete_task_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"check_delete_task"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/check_member_before_delete_pic(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_task_check_member_before_delete_pic_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"check_member_before_delete_pic"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/copy(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_task_copy_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/delete_member_in_task(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_task_delete_member_in_task_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"delete_member_in_task"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/get_list_participants(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_task_get_list_participants_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"get_list_participants"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/is_delete_permission(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_task_is_delete_permission_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"is_delete_permission"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/much_quantity(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_task_much_quantity_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"much_quantity"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/orders_between_company(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_task_orders_between_company_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"orders_between_company"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/remove_user_task_permission(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_task_remove_user_task_permission_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"remove_user_task_permission"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/show_modal_delete_task_child(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_task_show_modal_delete_task_child_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"show_modal_delete_task_child"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/update_task_name(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_task_update_task_name_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"update_task_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_tasks_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tickets/:ticket_id/approve_request_order(.:format)
 * @param {any} project_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_ticket_approve_request_order_path = __jsr.r({"project_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"approve_request_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tickets/:ticket_id/check_order_of_company(.:format)
 * @param {any} project_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_ticket_check_order_of_company_path = __jsr.r({"project_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"check_order_of_company"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tickets/:ticket_id/check_pending_quantity(.:format)
 * @param {any} project_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_ticket_check_pending_quantity_path = __jsr.r({"project_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"check_pending_quantity"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tickets/:ticket_id/check_quantity_zero(.:format)
 * @param {any} project_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_ticket_check_quantity_zero_path = __jsr.r({"project_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"check_quantity_zero"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tickets/:ticket_id/check_remove_all_members(.:format)
 * @param {any} project_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_ticket_check_remove_all_members_path = __jsr.r({"project_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"check_remove_all_members"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tickets/:ticket_id/copy_ticket(.:format)
 * @param {any} project_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_ticket_copy_ticket_path = __jsr.r({"project_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"copy_ticket"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tickets/:ticket_id/fetch_orders_for_client(.:format)
 * @param {any} project_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_ticket_fetch_orders_for_client_path = __jsr.r({"project_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"fetch_orders_for_client"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tickets/:ticket_id/fetch_orders_for_supplier(.:format)
 * @param {any} project_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_ticket_fetch_orders_for_supplier_path = __jsr.r({"project_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"fetch_orders_for_supplier"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tickets/:ticket_id/get_list_participants(.:format)
 * @param {any} project_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_ticket_get_list_participants_path = __jsr.r({"project_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"get_list_participants"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tickets/:ticket_id/orders(.:format)
 * @param {any} project_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_ticket_orders_path = __jsr.r({"project_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tickets/:ticket_id/reject_request_order(.:format)
 * @param {any} project_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_ticket_reject_request_order_path = __jsr.r({"project_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"reject_request_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tickets/:ticket_id/remove_member_unapprove(.:format)
 * @param {any} project_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_ticket_remove_member_unapprove_path = __jsr.r({"project_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"remove_member_unapprove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tickets/:ticket_id/remove_request_unapprove(.:format)
 * @param {any} project_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_ticket_remove_request_unapprove_path = __jsr.r({"project_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"remove_request_unapprove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tickets/:ticket_id/save_work_order_request_with_supplier(.:format)
 * @param {any} project_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_ticket_save_work_order_request_with_supplier_path = __jsr.r({"project_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"save_work_order_request_with_supplier"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tickets/:ticket_id/save_work_order_with_client(.:format)
 * @param {any} project_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_ticket_save_work_order_with_client_path = __jsr.r({"project_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"save_work_order_with_client"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tickets/:ticket_id/send_mail_with_create_ticket(.:format)
 * @param {any} project_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_ticket_send_mail_with_create_ticket_path = __jsr.r({"project_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"send_mail_with_create_ticket"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tickets/:ticket_id/suppliers(.:format)
 * @param {any} project_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_ticket_suppliers_path = __jsr.r({"project_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"suppliers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tickets/:ticket_id/update_ticket_pics(.:format)
 * @param {any} project_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_ticket_update_ticket_pics_path = __jsr.r({"project_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"update_ticket_pics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/update_status_project(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_project_update_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[6,"update_status_project"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_projects_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"projects"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /projects/:id/public_user(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_public_user_in_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"public_user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/quick_update_user_permission(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_quick_update_user_permission_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"quick_update_user_permission"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/add_project(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_quotation_add_project_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[6,"add_project"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /documents/quotations/:quotation_id/approval(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_quotation_approval_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"approval"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/change_list_plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_quotation_change_list_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[6,"change_list_plans"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/check_exchange_order(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_quotation_check_exchange_order_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"check_exchange_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/check_release_date(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_quotation_check_release_date_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"check_release_date"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/closed(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_quotation_close_quotation_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"closed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/quotations/:quotation_id/copy(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_quotation_copy_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/copy(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_quotation_copy_quotation_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/quotations/:quotation_id/edit(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_quotation_edit_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/quotations/:quotation_id/order_received(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_quotation_order_received_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"order_received"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/quotations/:quotation_id/reject(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_quotation_reject_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"reject"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/quotations/:quotation_id/released(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_quotation_released_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"released"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/select_plan_items(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_quotation_select_plan_items_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[6,"select_plan_items"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /documents/quotations/:quotation_id(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_quotation_show_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/quotations/:quotation_id/submitted(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_quotation_submitted_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"submitted"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/quotations/:quotation_id/update(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_quotation_update_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/quotations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_quotations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"quotations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/received_quotation(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_received_quotation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"received_quotation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /signup_user/keycode(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_register_user_for_keycode_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signup_user"],[2,[7,"/"],[2,[6,"keycode"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/task/:id/release(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_release_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"release"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/task/:id/remove_member_unapprove(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_remove_member_unapprove_of_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_member_unapprove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/task/:id/remove_request_unapprove(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_remove_request_unapprove_of_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_request_unapprove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /company/setting_email/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_remove_setting_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"setting_email"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /request_admin_to_invite_members/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_request_admin_to_invite_member_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"request_admin_to_invite_members"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/request_for_quotations(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_request_for_quotations_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"request_for_quotations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /subscriptions/rollback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_rollback_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"rollback"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /client/save_signature_contract_revision(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_save_signature_contract_revision_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"client"],[2,[7,"/"],[2,[6,"save_signature_contract_revision"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/authority(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_save_user_permirssion_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"authority"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /select_project_of_request_for_quotation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_select_project_of_request_for_quotation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"select_project_of_request_for_quotation"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /send_contact_us(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_send_contact_us_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"send_contact_us"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /projects/:id/send_mail_to_default_permission(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_send_mail_to_default_permission_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_mail_to_default_permission"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/task/:id/send_mail_to_default_permission_of_task(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_send_mail_to_default_permission_of_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_mail_to_default_permission_of_task"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /set_owner(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_set_owner_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"set_owner"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /clients/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_show_client_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /documents/submission/sale/invoice/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_show_invoices_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"submission"],[2,[7,"/"],[2,[6,"sale"],[2,[7,"/"],[2,[6,"invoice"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /owners(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_show_list_owner_of_client_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"owners"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /suppliers/contract_revision/:id/note(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_show_note_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[6,"contract_revision"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/:owner_id/show(.:format)
 * @param {any} owner_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_show_owner_of_client_path = __jsr.r({"owner_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"owner_id"],[2,[7,"/"],[2,[6,"show"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/contract_revision/:id/content(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_show_pdf_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[6,"contract_revision"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"content"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/contract_revision/:id/content_download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_show_pdf_download_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[6,"contract_revision"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"content_download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/submission/sale/order/:order_id(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_show_pdf_order_of_quotation_path = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"submission"],[2,[7,"/"],[2,[6,"sale"],[2,[7,"/"],[2,[6,"order"],[2,[7,"/"],[2,[3,"order_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/submission/payment/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_show_pdf_payment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"submission"],[2,[7,"/"],[2,[6,"payment"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/submission/sale/quotation/:quotation_id(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_show_pdf_quotation_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"submission"],[2,[7,"/"],[2,[6,"sale"],[2,[7,"/"],[2,[6,"quotation"],[2,[7,"/"],[2,[3,"quotation_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/show/:quotation_id/show_pdf(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_show_pdf_quotation_pilicy_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[6,"show"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"show_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/receipt/sale/purchasing/:order_id(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_show_pdf_receipt_documents_path = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"receipt"],[2,[7,"/"],[2,[6,"sale"],[2,[7,"/"],[2,[6,"purchasing"],[2,[7,"/"],[2,[3,"order_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/submission/purchasing/:order_id(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_show_pdf_sender_documents_path = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"submission"],[2,[7,"/"],[2,[6,"purchasing"],[2,[7,"/"],[2,[3,"order_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /company/portfolios(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_show_portfolios_of_supplier_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"portfolios"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_show_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /signin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_signin_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signin"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /clients/:id/re-signin(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_signin_as_client_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"re-signin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /signup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_signup_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signup"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /signup/step3/:member_plan_id/:register_token(.:format)
 * @param {any} member_plan_id
 * @param {any} register_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_signup_new_path = __jsr.r({"member_plan_id":{"r":true},"register_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signup"],[2,[7,"/"],[2,[6,"step3"],[2,[7,"/"],[2,[3,"member_plan_id"],[2,[7,"/"],[2,[3,"register_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /signup/thanks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_signup_thanks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signup"],[2,[7,"/"],[2,[6,"thanks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /company/contract/invoice/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_subscription_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"contract"],[2,[7,"/"],[2,[6,"invoice"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /company/contract/invoice(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_subscription_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"contract"],[2,[7,"/"],[2,[6,"invoice"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /signup/step2/:register_token(.:format)
 * @param {any} register_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_subscriptions_path = __jsr.r({"register_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signup"],[2,[7,"/"],[2,[6,"step2"],[2,[7,"/"],[2,[3,"register_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /subscriptions/upgrade_to_owner(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_subscriptions_upgrade_to_owner_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"upgrade_to_owner"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/success/:keycode(.:format)
 * @param {any} keycode
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_success_user_path = __jsr.r({"keycode":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"success"],[2,[7,"/"],[2,[3,"keycode"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:supplier_id/bank(.:format)
 * @param {any} supplier_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_supplier_bank_accounts_path = __jsr.r({"supplier_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"supplier_id"],[2,[7,"/"],[2,[6,"bank"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id/be_invited(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_supplier_be_invited_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"be_invited"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /supplier/comment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_supplier_comment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"supplier"],[2,[7,"/"],[2,[6,"comment"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_supplier_download_csv_path = __jsr.r({"format":{"d":"csv"}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /option/supplier_information(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_supplier_information_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"option"],[2,[7,"/"],[2,[6,"supplier_information"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:supplier_id/portfolios/:id(.:format)
 * @param {any} supplier_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_supplier_portfolio_path = __jsr.r({"supplier_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"supplier_id"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:supplier_id/portfolios(.:format)
 * @param {any} supplier_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_supplier_portfolios_path = __jsr.r({"supplier_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"supplier_id"],[2,[7,"/"],[2,[6,"portfolios"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/quick_approve_invitation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_supplier_quick_approve_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[6,"quick_approve_invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/re_invite(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_supplier_re_invite_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[6,"re_invite"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/reject_invitation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_supplier_reject_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[6,"reject_invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/remove_invitation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_supplier_remove_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[6,"remove_invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id/waiting_approve(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_supplier_waiting_approve_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"waiting_approve"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_suppliers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /suppliers/check_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_suppliers_check_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[6,"check_email"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/show_activity(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_task_activity_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"show_activity"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_task_update_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/invite(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_tasks_invite_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"invite"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /company/contract(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_total_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"contract"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /unread(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /subscriptions/update_all_info/with_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_update_all_info_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"update_all_info"],[2,[7,"/"],[2,[6,"with_password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_update_client_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /owners/:id/payment_edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_update_client_payment_method_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"payment_edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /company/info/update_company_profiles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_update_company_profiles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"update_company_profiles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/update/default_manager(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_update_default_maneger_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"update"],[2,[7,"/"],[2,[6,"default_manager"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /payment/update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_update_payment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"payment"],[2,[7,"/"],[2,[6,"update"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/update_permission_all(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_update_permission_all_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_permission_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/task/:id/update_pics(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_update_pics_of_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_pics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /company/portfolios(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_update_portfolios_of_supplier_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"portfolios"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /company/info/update_publication_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_update_publication_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"update_publication_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/orders/:id/update_status(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_update_status_order_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /works/update_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_update_status_work_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"works"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /update/supplier_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_update_supplier_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"update"],[2,[7,"/"],[2,[6,"supplier_status"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/update_user_permission(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_update_user_permission_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_user_permission"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/update_user_permission(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_update_user_permission_in_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_user_permission"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/resend_mail_invite_member(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_users_resend_mail_invite_member_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"resend_mail_invite_member"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/send_mail_approve(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_users_send_mail_approve_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"send_mail_approve"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/update_approve_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_users_update_approve_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"update_approve_status"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /privacy_and_policy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_view_policy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"privacy_and_policy"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /term_conditions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owner_view_term_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"term_conditions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /akaire_file/:object_id/get_list_participants(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const participants_for_akaire_file_index_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"get_list_participants"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id/payment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payment_method_owner_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id/payment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payment_owner_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/invoices/payment_subscription/:invoice_id(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payment_subscription_admins_invoices_path = __jsr.r({"invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"payment_subscription"],[2,[7,"/"],[2,[3,"invoice_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /orders/execute_payment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const paypal_execute_payment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"execute_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orders/paypal_sub_plan_id/:membership_plan_id(.:format)
 * @param {any} membership_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const paypal_sub_plan_id_path = __jsr.r({"membership_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"paypal_sub_plan_id"],[2,[7,"/"],[2,[3,"membership_plan_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/submission/sale/invoice/:id/receipt(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pdf_money_paid_owner_invoices_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"submission"],[2,[7,"/"],[2,[6,"sale"],[2,[7,"/"],[2,[6,"invoice"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"receipt"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/:id/pdf_money_paid(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pdf_money_paid_supplier_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pdf_money_paid"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /person_in_charges/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const person_in_charge_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"person_in_charges"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orders/person_in_charge(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const person_in_charge_owner_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"person_in_charge"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /owners/:id/person_in_charges(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const person_in_charge_owner_owner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"person_in_charges"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:id/manager(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const person_in_charges_owner_client_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"manager"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id/manager(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const person_in_charges_owner_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"manager"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/clients/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const post_create_client_admins_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/invite/:supplier_id(.:format)
 * @param {any} supplier_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const post_invite_owner_suppliers_path = __jsr.r({"supplier_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[6,"invite"],[2,[7,"/"],[2,[3,"supplier_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id/post_new_contract(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const post_new_contract_owner_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"post_new_contract"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id/contracts/:contract_id/new_revision(.:format)
 * @param {any} id
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const post_new_contract_revision_owner_supplier_path = __jsr.r({"id":{"r":true},"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"new_revision"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/:id/person_in_charges(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const post_person_in_charge_owner_owner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"person_in_charges"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:id/manager(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const post_person_in_charges_owner_client_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"manager"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id/person_in_charges(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const post_person_in_charges_owner_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"person_in_charges"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaires/preview_animation/:animation_id(.:format)
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_animation_akaire_feature_akaires_path = __jsr.r({"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaires"],[2,[7,"/"],[2,[6,"preview_animation"],[2,[7,"/"],[2,[3,"animation_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /company/private_domain(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const private_domain_owner_company_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"private_domain"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profile_owner_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /unread/project(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const project_owner_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[2,[7,"/"],[2,[6,"project"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/:project_id/project_participants(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const project_participants_akaire_file_index_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"project_participants"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/project_participants(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const project_participants_owner_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"project_participants"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /unread/project(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const project_supplier_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[2,[7,"/"],[2,[6,"project"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/quantity_of_company(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const quantity_of_company_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"quantity_of_company"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /collaborators/quick_approve_invitation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const quick_approve_invitation_owner_collaborators_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[6,"quick_approve_invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/quick_create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const quick_create_owner_projects_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[6,"quick_create"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/check_release_date(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const quotation_check_release_date_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"check_release_date"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/closed(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const quotation_closed_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"closed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /option/quotation_consultation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const quotation_consultation_owner_discussions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"option"],[2,[7,"/"],[2,[6,"quotation_consultation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/work_present(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const quotation_work_present_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"work_present"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /collaborators/re_invite(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const re_invite_owner_collaborators_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[6,"re_invite"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /news/readed_all(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const readed_all_news_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[6,"readed_all"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /messages/readed(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const readed_messages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"readed"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /news/readed(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const readed_news_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[6,"readed"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /invoices/:id/receipt(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const receipt_owner_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"receipt"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /company/contract/invoice/:id/receipt(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const receipt_owner_subscription_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"contract"],[2,[7,"/"],[2,[6,"invoice"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"receipt"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/:id/receipt(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const receipt_supplier_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"receipt"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /subscriptions/register_member_paid(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const register_member_paid_owner_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"register_member_paid"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /registers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const registers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"registers"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /collaborators/reject_invitation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reject_invitation_owner_collaborators_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[6,"reject_invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /request_admin_to_invite_members/:id/reject(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reject_owner_request_admin_to_invite_member_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"request_admin_to_invite_members"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reject"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /efforts/reject_work(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reject_work_efforts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"efforts"],[2,[7,"/"],[2,[6,"reject_work"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /efforts/release(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const release_efforts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"efforts"],[2,[7,"/"],[2,[6,"release"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orders/release(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const release_owner_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"release"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orders/release(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const release_supplier_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"release"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/remove_client(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_client_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"remove_client"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /handle_contract/remove_contract(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_contract_owner_handle_contract_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"handle_contract"],[2,[7,"/"],[2,[6,"remove_contract"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /collaborators/remove_invitation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_invitation_owner_collaborators_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[6,"remove_invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/:id/remove_public_request(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_public_request_admins_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_public_request"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/remove_supplier(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_supplier_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"remove_supplier"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/remove_user_when_unapproved(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_user_when_unapproved_owner_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_user_when_unapproved"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/check_list_work/render_check_list_work(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const render_check_list_work_owner_project_check_list_work_index_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"check_list_work"],[2,[7,"/"],[2,[6,"render_check_list_work"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /orders/revise/:membership_plan_id/subscription_id/:subscription_id(.:format)
 * @param {any} membership_plan_id
 * @param {any} subscription_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const revise_path = __jsr.r({"membership_plan_id":{"r":true},"subscription_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"revise"],[2,[7,"/"],[2,[3,"membership_plan_id"],[2,[7,"/"],[2,[6,"subscription_id"],[2,[7,"/"],[2,[3,"subscription_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/save_animation_embed(.:format)
 * @param {any} akaire_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_animation_embed_akaire_feature_akaire_file_animations_path = __jsr.r({"akaire_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"save_animation_embed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaires/save_animation_embed(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_animation_embed_akaire_feature_akaires_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaires"],[2,[7,"/"],[2,[6,"save_animation_embed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/save_animation_embed(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_animation_embed_akaire_feature_project_animations_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"save_animation_embed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/save_animation_embed(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_animation_embed_akaire_feature_project_task_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"save_animation_embed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/save_animation_embed(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_animation_embed_akaire_feature_project_task_ticket_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"save_animation_embed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/save_animation_embed(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_animation_embed_object_animations_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"save_animation_embed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/save_animation_embed(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_animation_embed_object_ticket_animations_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"save_animation_embed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/save(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"save"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /handle_contract/save_signature_contract_revision(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_signature_contract_revision_owner_handle_contract_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"handle_contract"],[2,[7,"/"],[2,[6,"save_signature_contract_revision"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /handle_contract/save_signature(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_signature_owner_handle_contract_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"handle_contract"],[2,[7,"/"],[2,[6,"save_signature"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /handle_contract/save_signature_partner_contract_revision(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_signature_partner_contract_revision_owner_handle_contract_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"handle_contract"],[2,[7,"/"],[2,[6,"save_signature_partner_contract_revision"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/save(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_supplier_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"save"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/save_unit_for_company(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_unit_for_company_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"save_unit_for_company"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/screen_shot(.:format)
 * @param {any} akaire_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const screen_shot_akaire_feature_akaire_file_animations_path = __jsr.r({"akaire_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"screen_shot"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/animations/screen_shot(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const screen_shot_akaire_feature_project_animations_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"screen_shot"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/screen_shot(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const screen_shot_akaire_feature_project_task_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"screen_shot"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/screen_shot(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const screen_shot_akaire_feature_project_task_ticket_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"screen_shot"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaires/screen_shot_multiples(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const screen_shot_multiples_akaire_feature_akaires_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaires"],[2,[7,"/"],[2,[6,"screen_shot_multiples"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/screen_shot(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const screen_shot_object_animations_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"screen_shot"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/tickets/:ticket_id/animations/screen_shot(.:format)
 * @param {any} object_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const screen_shot_object_ticket_animations_path = __jsr.r({"object_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"screen_shot"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:akaire_file_id/animations/screen_shot_youtube(.:format)
 * @param {any} akaire_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const screen_shot_youtube_akaire_feature_akaire_file_animations_path = __jsr.r({"akaire_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"screen_shot_youtube"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/animations/screen_shot_youtube(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const screen_shot_youtube_akaire_feature_project_task_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"screen_shot_youtube"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:task_id/tickets/:ticket_id/animations/screen_shot_youtube(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const screen_shot_youtube_akaire_feature_project_task_ticket_animations_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"screen_shot_youtube"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/screen_shot_youtube(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const screen_shot_youtube_object_animations_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[6,"screen_shot_youtube"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /messages/search_message(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_message_messages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"search_message"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /campaigns/send_invite(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_invite_owner_campaigns_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[6,"send_invite"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member_stores/send_invite(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_invite_owner_member_stores_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member_stores"],[2,[7,"/"],[2,[6,"send_invite"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:object_id/animations/:animation_id/animation_markers/send_mai_not_login(.:format)
 * @param {any} object_id
 * @param {any} animation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_mai_not_login_object_animation_animation_markers_path = __jsr.r({"object_id":{"r":true},"animation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"animations"],[2,[7,"/"],[2,[3,"animation_id"],[2,[7,"/"],[2,[6,"animation_markers"],[2,[7,"/"],[2,[6,"send_mai_not_login"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/news/send_test(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_test_admins_notices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[6,"send_test"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaires/send_upload_report_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_upload_report_email_akaire_feature_akaires_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaires"],[2,[7,"/"],[2,[6,"send_upload_report_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/news/set_banner(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_banner_admins_notices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[6,"set_banner"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/news/set_maintenance(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_maintenance_admins_notices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[6,"set_maintenance"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/:akaire_file_id/set_password_for_share(.:format)
 * @param {any} akaire_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_password_for_share_akaire_file_index_path = __jsr.r({"akaire_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[3,"akaire_file_id"],[2,[7,"/"],[2,[6,"set_password_for_share"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/news/set_show_for_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_show_for_user_admins_notices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[6,"set_show_for_user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/setting_show_again_confirm_type_task(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setting_show_again_confirm_type_task_owner_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"setting_show_again_confirm_type_task"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/setting_show_again_new_project(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setting_show_again_new_project_owner_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"setting_show_again_new_project"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/setting_show_again_notify(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setting_show_again_notify_akaire_file_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[6,"setting_show_again_notify"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/setting_show_again_notify(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setting_show_again_notify_owner_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"setting_show_again_notify"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/setting_show_again(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setting_show_again_owner_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"setting_show_again"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/setting_show_again_project(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setting_show_again_project_owner_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"setting_show_again_project"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/setting_show_again_sort_customize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setting_show_again_sort_customize_owner_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"setting_show_again_sort_customize"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /approval_settings/:entity_type/:entity_id(.:format)
 * @param {any} entity_type
 * @param {any} entity_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_approval_settings_path = __jsr.r({"entity_type":{"r":true},"entity_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"approval_settings"],[2,[7,"/"],[2,[3,"entity_type"],[2,[7,"/"],[2,[3,"entity_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id/load_comment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_comment_owner_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"load_comment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/news/show_member(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_member_admins_notices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[6,"show_member"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /news/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_news_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /option/contract/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_owner_pages_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"option"],[2,[7,"/"],[2,[6,"contract"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /orders/show_pdf(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_pdf_supplier_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"show_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/:id/portfolios/show(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_portfolio_supplier_admins_companies_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[6,"show"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/show_remove_quantity_of_supplier(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_remove_quantity_of_supplier_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"show_remove_quantity_of_supplier"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id/contracts/:contract_id/sign_contract(.:format)
 * @param {any} id
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sign_contract_owner_supplier_path = __jsr.r({"id":{"r":true},"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"sign_contract"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /subscriptions/skip_warning_set_bank_method(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const skip_warning_set_bank_method_owner_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"skip_warning_set_bank_method"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orders/submitted(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const submitted_owner_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"submitted"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /password_resets/success(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const success_owner_password_resets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"password_resets"],[2,[7,"/"],[2,[6,"success"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /password_resets/success(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const success_supplier_password_resets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"password_resets"],[2,[7,"/"],[2,[6,"success"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /invoices/sum_amount_paid(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sum_amount_paid_owner_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"sum_amount_paid"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /invoices/sum_amount_received(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sum_amount_received_owner_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"sum_amount_received"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /get_plan_to_create_new_plan/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"get_plan_to_create_new_plan"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /activities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_activities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"activities"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /activities/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_activity_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/invite(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_add_user_invite_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"invite"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/invite_users(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_add_user_permirssion_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"invite_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /project/:project_id/task/:task_id/order/:id/approval(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_approval_task_order_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"project"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"order"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approval"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /project/:project_id/task/:id/approve(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_approve_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"project"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approve"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/basic_info(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_basic_info_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"basic_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/order/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_cancel_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[6,"order"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /project/:project_id/tasks/:id/choose_color(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_change_color_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"project"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"choose_color"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/change_show_time_client(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_change_show_time_client_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"change_show_time_client"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/:company_id/chat(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_chat_owner_path = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"chat"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/chat(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_chat_tasks_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"chat"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tickets/:id/chat(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_chat_tickets_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"chat"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:user_id/chat(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_chat_user_path = __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"chat"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /check_read_term(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_check_read_term_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"check_read_term"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/choose_color(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_choose_color_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"choose_color"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /company/bank_account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_company_bank_account_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"bank_account"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /completion(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_completion_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"completion"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /contact_us(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_contact_us_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contact_us"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/log_work(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_create_log_work_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"log_work"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /supplier_register_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_create_register_user_keycode_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"supplier_register_user"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/new(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_create_tasks_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_dashboard_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /owners/:id/person_in_charges/:user_id(.:format)
 * @param {any} id
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_delete_person_in_charges_path = __jsr.r({"id":{"r":true},"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"person_in_charges"],[2,[7,"/"],[2,[3,"user_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /owner/contract/:id/destroy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_destroy_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owner"],[2,[7,"/"],[2,[6,"contract"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /project/:project_id/tasks/:id/destroy_log_work/:work_id(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {any} work_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_destroy_log_work_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"work_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"project"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy_log_work"],[2,[7,"/"],[2,[3,"work_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /owner/contract_revision/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_destroy_revision_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owner"],[2,[7,"/"],[2,[6,"contract_revision"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/destroy_user_invite/:user_id(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_destroy_user_invite_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy_user_invite"],[2,[7,"/"],[2,[3,"user_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/invite/delete/:user_id(.:format)
 * @param {any} id
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_destroy_user_permission_supplier_path = __jsr.r({"id":{"r":true},"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"invite"],[2,[7,"/"],[2,[6,"delete"],[2,[7,"/"],[2,[3,"user_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/quotation/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_detail_request_quotation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[6,"quotation"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /discussions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_discussions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"discussions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /company/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_edit_supplier_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/task_management(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_edit_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"task_management"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/get_company(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_get_company_by_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_company"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/get_work_histosry(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_get_get_work_histosry_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_work_histosry"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /notice/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_get_notice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"notice"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /plan-detail/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_get_plan_detail_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"plan-detail"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /company/portfolios(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_get_portfolio_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"portfolios"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/show_quotation(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_get_quotation_present_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"show_quotation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/get_quotation(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_get_quotations_by_current_company_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_quotation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/get_user_permission/:user_id(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_get_user_permission_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_user_permission"],[2,[7,"/"],[2,[3,"user_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/get_users(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_get_users_by_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/get_users_permirssions(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_get_users_permirssions_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_users_permirssions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /grant_chart(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_grant_charts_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"grant_chart"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/invite(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_invite_tasks_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"invite"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/invite_users(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_invite_users_of_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"invite_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_invoice_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /notification/is_read(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_is_read_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"notification"],[2,[7,"/"],[2,[6,"is_read"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/task(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_list_grantcharts_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"task"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_list_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/orders_received(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_list_orders_received_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"orders_received"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/list_quotations(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_list_quotations_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"list_quotations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /list_request_for_quotations/receive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_list_request_for_quotations_receive_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"list_request_for_quotations"],[2,[7,"/"],[2,[6,"receive"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/list_request_quotation(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_list_request_quotation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"list_request_quotation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/:id/ticket(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_log_work_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"ticket"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /logout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_logout_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"logout"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /post-policy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_new_post_policy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"post-policy"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/new(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_new_tasks_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /owners/:id/approval(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_owner_approval_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approval"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/:id/contracts(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_owner_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contracts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/:id/destroy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_owner_delete_payment_revision_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/:id/contracts/new(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_owner_new_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/:id/contracts/:contract_id/new_revision(.:format)
 * @param {any} id
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_owner_new_contract_revision_path = __jsr.r({"id":{"r":true},"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"new_revision"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/:id/contract/new(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_owner_post_new_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contract"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/:id/contracts/:contract_id/new_revision(.:format)
 * @param {any} id
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_owner_post_new_contract_revision_path = __jsr.r({"id":{"r":true},"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"new_revision"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/:id/contracts/:contract_id/sign_contract(.:format)
 * @param {any} id
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_owner_sign_contract_path = __jsr.r({"id":{"r":true},"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"sign_contract"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /owners(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_owners_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"owners"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /owners/:id/payment_site(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_owners_payment_site_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"payment_site"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/:id/payment_site(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_owners_post_payment_site_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"payment_site"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/show/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_owners_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[6,"show"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pages/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_page_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_pages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pages"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pages/detail/:slug/:id(.:format)
 * @param {any} slug
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_pages_view_path = __jsr.r({"slug":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"detail"],[2,[7,"/"],[2,[3,"slug"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pages/view/:slug/:id(.:format)
 * @param {any} slug
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_pages_view_unauth_path = __jsr.r({"slug":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"view"],[2,[7,"/"],[2,[3,"slug"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /password_resets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_password_resets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"password_resets"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /payment_method(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_payment_method_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"payment_method"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /owners/:id/payment_term_revisions(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_payment_term_revisions_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"payment_term_revisions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /owners/:id/person_in_charges(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_person_in_charges_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"person_in_charges"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /plans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"plans"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /policy/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_policy_edit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"policy"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /policy/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_policy_new_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"policy"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /policy/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_policy_update_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"policy"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /portfolio/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_portfolio_new_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"portfolio"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /company/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_post_edit_supplier_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /project/:project_id/task/:task_id/post_order_received(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_post_orders_received_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"project"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"post_order_received"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /payment_method(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_post_payment_method_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"payment_method"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /owners/:id/person_in_charges(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_post_person_in_charges_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"person_in_charges"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /plans-policy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_post_policy_plan_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"plans-policy"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /company/portfolios(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_post_portfolio_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"portfolios"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /supplier_resend(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_post_resend_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"supplier_resend"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /signin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_post_signin_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signin"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /supplier_signup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_post_signup_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"supplier_signup"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /supplier_signup/step2/:register_token(.:format)
 * @param {any} register_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_post_signup_new_path = __jsr.r({"register_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"supplier_signup"],[2,[7,"/"],[2,[6,"step2"],[2,[7,"/"],[2,[3,"register_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /project/:id/chat(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_project_chat_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"project"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"chat"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/task/get_user(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_project_get_user_of_task_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[6,"get_user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_project_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"projects"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/quotation_present(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_project_quotation_present_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"quotation_present"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/request_details(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_project_request_details_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"request_details"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/activity(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_project_show_activity_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"activity"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_project_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/all_orders_between_company(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_project_task_all_orders_between_company_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"all_orders_between_company"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/change_status(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_project_task_change_status_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"change_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/copy(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_project_task_copy_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/is_delete_permission(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_project_task_is_delete_permission_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"is_delete_permission"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/much_quantity(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_project_task_much_quantity_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"much_quantity"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/orders_between_company(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_project_task_orders_between_company_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"orders_between_company"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/remove_user_task_permission(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_project_task_remove_user_task_permission_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"remove_user_task_permission"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_project_tasks_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/change_list_plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_quotation_change_list_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[6,"change_list_plans"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /documents/quotations/:quotation_id/copy(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_quotation_copy_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/copy(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_quotation_copy_quotation_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_quotation_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/destroy(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_quotation_destroy_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/quotations/:quotation_id/edit(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_quotation_edit_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/edit/:quotation_id(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_quotation_edit_no_project_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[6,"edit"],[2,[7,"/"],[2,[3,"quotation_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/projects/:project_id/quotations/new(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_quotation_new_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/quotations/:quotation_id/order_received(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_quotation_order_received_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"order_received"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/policy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_quotation_post_policy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[6,"policy"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /documents/quotations/:quotation_id/reject(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_quotation_reject_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"reject"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/select_plan_items(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_quotation_select_plan_items_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[6,"select_plan_items"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /documents/quotations/:quotation_id/show(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_quotation_show_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"show"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/show/:quotation_id(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_quotation_show_no_project_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[6,"show"],[2,[7,"/"],[2,[3,"quotation_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/quotations/:quotation_id/submitted(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_quotation_submitted_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"submitted"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/quotations/:quotation_id/update(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_quotation_update_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/update/:quotation_id(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_quotation_update_no_project_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[6,"update"],[2,[7,"/"],[2,[3,"quotation_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/quotations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_quotations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"quotations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /quotations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_quotations_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /supplier_registers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_register_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"supplier_registers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /supplier_signup_user/keycode(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_register_user_for_keycode_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"supplier_signup_user"],[2,[7,"/"],[2,[6,"keycode"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /supplier_registers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_registers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"supplier_registers"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /projects/:id/invite_users(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_save_user_permirssion_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"invite_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /send_contact_us(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_send_contact_us_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"send_contact_us"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /owner/contract_revision/:id/note(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_show_note_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owner"],[2,[7,"/"],[2,[6,"contract_revision"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /owner/contract_revision/:id/content(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_show_pdf_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owner"],[2,[7,"/"],[2,[6,"contract_revision"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"content"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/:id/show(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_show_pdf_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"show"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/quotation(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_show_pdf_quotation_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"quotation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/show/:quotation_id/show_pdf(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_show_pdf_quotation_pilicy_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[6,"show"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"show_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /project/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_show_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"project"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_signin_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signin"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /supplier_signup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_signup_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"supplier_signup"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /supplier_signup/step2/:register_token(.:format)
 * @param {any} register_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_signup_new_path = __jsr.r({"register_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"supplier_signup"],[2,[7,"/"],[2,[6,"step2"],[2,[7,"/"],[2,[3,"register_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /supplier_signup/success(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_signup_success_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"supplier_signup"],[2,[7,"/"],[2,[6,"success"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/success/:keycode(.:format)
 * @param {any} keycode
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_success_user_path = __jsr.r({"keycode":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"success"],[2,[7,"/"],[2,[3,"keycode"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:search(.:format)
 * @param {any} search
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_suggest_search_path = __jsr.r({"search":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/edit(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_supplier_quotation_edit_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/order_received(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_supplier_quotation_order_received_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"order_received"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/reject(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_supplier_quotation_reject_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"reject"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/show(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_supplier_quotation_show_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"show"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/submitted(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_supplier_quotation_submitted_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"submitted"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:quotation_id/update(.:format)
 * @param {any} quotation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_supplier_quotation_update_path = __jsr.r({"quotation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"quotation_id"],[2,[7,"/"],[2,[6,"update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /supplier_signup/thanks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_supplier_signup_thanks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"supplier_signup"],[2,[7,"/"],[2,[6,"thanks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/show_activity(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_task_activity_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"show_activity"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /project/:project_id/task/:id(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_task_update_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"project"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /company/total_info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_total_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"total_info"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /unread(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /company/info/update_company_profiles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_update_company_profiles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"update_company_profiles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/update/default_manager(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_update_default_maneger_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"update"],[2,[7,"/"],[2,[6,"default_manager"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /company/info/update_publication_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_update_publication_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"update_publication_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /works/update_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_update_status_work_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"works"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/update_user_permission(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_update_user_permission_in_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_user_permission"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /supplier_privacy_and_policy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_view_policy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"supplier_privacy_and_policy"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /supplier_term_conditions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_view_term_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"supplier_term_conditions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /unread/task(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const task_owner_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[2,[7,"/"],[2,[6,"task"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /unread/task(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const task_supplier_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[2,[7,"/"],[2,[6,"task"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const thanks_owner_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const thanks_supplier_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /unread/ticket(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticket_owner_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[2,[7,"/"],[2,[6,"ticket"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /unread/ticket(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticket_supplier_unread_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unread"],[2,[7,"/"],[2,[6,"ticket"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:id/tickets_data(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tickets_data_akaire_feature_project_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tickets_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:id/tickets_data(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tickets_data_object_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tickets_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/transit_akaire(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transit_akaire_owner_projects_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[6,"transit_akaire"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /efforts/undelivered_works(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const undelivered_works_efforts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"efforts"],[2,[7,"/"],[2,[6,"undelivered_works"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orders/unsubscribe/:subscription_id(.:format)
 * @param {any} subscription_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unsubscribe_path = __jsr.r({"subscription_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"unsubscribe"],[2,[7,"/"],[2,[3,"subscription_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/clients/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_client_admins_companies_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/update_display_type_file(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_display_type_file_akaire_file_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[6,"update_display_type_file"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /object_sorts/update_effort_sort(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_effort_sort_object_sorts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"object_sorts"],[2,[7,"/"],[2,[6,"update_effort_sort"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/update_max_quantity(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_max_quantity_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"update_max_quantity"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /object_sorts/:id/update_name(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_name_object_sort_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"object_sorts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/update_notify_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_notify_status_akaire_file_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[6,"update_notify_status"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/update_notify_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_notify_status_owner_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_notify_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:id/update_notify_status(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_notify_status_owner_project_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_notify_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /password_resets/:token/update(.:format)
 * @param {any} token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_password_owner_password_resets_path = __jsr.r({"token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"password_resets"],[2,[7,"/"],[2,[3,"token"],[2,[7,"/"],[2,[6,"update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /password_resets/:token/update(.:format)
 * @param {any} token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_password_supplier_password_resets_path = __jsr.r({"token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"password_resets"],[2,[7,"/"],[2,[3,"token"],[2,[7,"/"],[2,[6,"update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/:object_id/update_pic_for_task_or_ticket(.:format)
 * @param {any} object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_pic_for_task_or_ticket_akaire_file_index_path = __jsr.r({"object_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[3,"object_id"],[2,[7,"/"],[2,[6,"update_pic_for_task_or_ticket"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/update_pic_list(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_pic_list_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"update_pic_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/:id/update_profile_publication_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_profile_publication_status_admins_companies_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_profile_publication_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/update_project_name(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_project_name_owner_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_project_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/delay_payments/:id/update_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_status_admins_delay_payment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"delay_payments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/companies/supplier/:id/detail(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_supplier_admins_companies_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"supplier"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"detail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /company_ip_limits/update_type_limit_ip(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_type_limit_ip_owner_company_ip_limits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company_ip_limits"],[2,[7,"/"],[2,[6,"update_type_limit_ip"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/:project_id/tasks/:task_id/ticket/update_unit_quantity(.:format)
 * @param {any} project_id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_unit_quantity_owner_project_task_ticket_path = __jsr.r({"project_id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"ticket"],[2,[7,"/"],[2,[6,"update_unit_quantity"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:id/update_use_payment_function(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_use_payment_function_owner_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_use_payment_function"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_file/:project_id/quick_update_user_permission(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_user_permission_akaire_file_index_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_file"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"quick_update_user_permission"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /subscriptions/upgrade_to_owner(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upgrade_to_owner_supplier_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"upgrade_to_owner"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/upload_avatar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_avatar_owner_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"upload_avatar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaires/upload_multiple_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_multiple_files_akaire_feature_akaires_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaires"],[2,[7,"/"],[2,[6,"upload_multiple_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/:id/uploaded_data_info(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const uploaded_data_info_akaire_feature_akaire_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"uploaded_data_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaires/uploaded_data_info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const uploaded_data_info_akaire_feature_akaires_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaires"],[2,[7,"/"],[2,[6,"uploaded_data_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/projects/:project_id/tasks/:id/uploaded_data_info(.:format)
 * @param {any} project_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const uploaded_data_info_akaire_feature_project_task_path = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"uploaded_data_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/objects/:id/uploaded_data_info(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const uploaded_data_info_object_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"objects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"uploaded_data_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /request_admin_to_invite_members/:id/user_accept(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_accept_owner_request_admin_to_invite_member_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"request_admin_to_invite_members"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"user_accept"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /request_admin_to_invite_members/:id/user_accept_register_new(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_accept_register_new_owner_request_admin_to_invite_member_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"request_admin_to_invite_members"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"user_accept_register_new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /request_admin_to_invite_members/:id/user_check_request(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_check_request_owner_request_admin_to_invite_member_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"request_admin_to_invite_members"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"user_check_request"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /request_admin_to_invite_members/:id/user_reject(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_reject_owner_request_admin_to_invite_member_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"request_admin_to_invite_members"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"user_reject"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /akaire_feature/akaire_files/user_update_not_show_modal_reply_not_login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_update_not_show_modal_reply_not_login_akaire_feature_akaire_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"akaire_feature"],[2,[7,"/"],[2,[6,"akaire_files"],[2,[7,"/"],[2,[6,"user_update_not_show_modal_reply_not_login"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /option/contract/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const view_owner_pages_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"option"],[2,[7,"/"],[2,[6,"contract"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/privacy_and_terms/:id/view_policy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const view_policy_admins_privacy_and_terms_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"privacy_and_terms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"view_policy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /collaborators/:id/waiting_approve(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const waiting_approve_owner_collaborator_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"waiting_approve"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /orders/webhook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhook_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"webhook"],[1,[2,[8,"."],[3,"format"]]]]]]]);


